// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import i18n from '../../i18n';
import design_element_6 from "../../assets/media/images/interb2bike.png";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";
import design_element_7 from "../../assets/media/images/borseSmall.png";
import design_element_8 from "../../assets/media/images/jandc.png";
import design_element_11 from "../../assets/media/images/naviplan.png";
import design_element_12 from "../../assets/media/images/danfos.png";
import design_element_13 from "../../assets/media/images/alakreform.png";
import design_element_14 from "../../assets/media/images/myensign.png";

import design_element_15 from "../../assets/media/images/Group 25.png";
import design_element_16 from "../../assets/media/images/Group 24.png";
import design_element_17 from "../../assets/media/images/references.png";

import cursor from "../../assets/media/images/cursor.svg";
import pay from "../../assets/media/images/pay.svg";
import swap from "../../assets/media/images/swap.svg";
import database from "../../assets/media/images/database.svg";

import email from "../../assets/media/images/email.svg";
import ui from "../../assets/media/images/ui.svg";
import filters from "../../assets/media/images/filters.svg";
import discount from "../../assets/media/images/discount.svg";

import toolkit from "../../assets/media/images/toolkit.svg";
import invoice from "../../assets/media/images/invoice.svg";
import business from "../../assets/media/images/business.svg";
import tax from "../../assets/media/images/tax.svg";

import shop from "../../assets/media/images/shop.svg";
import valuta from "../../assets/media/images/valuta-1.svg";
import catalog from "../../assets/media/images/catalog.svg";
import designer from "../../assets/media/images/3d.svg";

import { MDBRow, MDBCol, MDBCard, MDBContainer
   } from "mdb-react-ui-kit";

const ReferencesSection = (props) => {

 // MULTILANGUAGE
 const { t } = i18n;

 const responsive = {
  superWidthDesktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 3,
      partialVisibilityGutter: 20
    }
};


  return (
    <div id="references">
      <div className="references-div pb-5" style={{width: "100%"}}>
        <img src={design_element_17} className="design-element-17"/>
        <div className="d-flex justify-content-start align-items-center" style={{width: "100%"}}>
          <img src={design_element_15} className="design-element-15"/>
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{width: "100%"}}>
          <span>{t('references')}</span>
        </div>
        <div className="d-flex justify-content-end align-items-center" style={{width: "100%"}}>
          <img src={design_element_16} className="design-element-16"/>
        </div>
      </div>

    <section className="fourth-section"  >
      
      <div className="top-section pt-5">
        <div className="title-text d-flex justify-content-center align-items-center">
            <span>InterB2Bike</span>
        </div>
        <div className="link-text d-flex justify-content-center align-items-center">
            <a href="https://interb2bike.com" target="_blank">{t('open_website')}</a>
        </div>
        <div className="description-text d-flex justify-content-center align-items-center">
            <span>{t('interb2bike_is_a_unique')}</span>
        </div>
      </div>
      <MDBRow className="m-0">
        <MDBCol center md="3">
          <div className="reference-dscripton pb-5">
            <div className="first-element d-flex justify-content-end align-items-center">
              <img src={cursor}/>
            </div>
            <div className="second-element">
              <span className="d-flex align-items-center justify-content-end">{t('user_friendly_design')}</span>
            </div>
            <div className="third-element">
              <span className="d-flex align-items-center">{t('the_design_and_layout')}</span>
            </div>
          </div>
          <div className="reference-dscripton">
            <div className="first-element d-flex justify-content-end align-items-center">
              <img src={swap}/>
            </div>
            <div className="second-element">
              <span className="d-flex align-items-center justify-content-end">{t('real_time_data')}</span>
            </div>
            <div className="third-element">
              <span className="d-flex align-items-center">{t('the_data_you_see')}</span>
            </div>
          </div>
        </MDBCol>
        <MDBCol md="6" className="reference-card-col">
          <img src={design_element_6} className="deisgn-element-6"/>
        </MDBCol>
        <MDBCol center md="3">
        <div className="reference-dscripton pb-5">
          <div className="first-element">
            <img src={pay}/>
          </div>
          <div className="second-element">
            <span className="d-flex align-items-center justify-content-start">{t('integrated_payment')}</span>
          </div>
          <div className="fourth-element">
            <span className="d-flex align-items-center">{t('we_integrated_stripe')}</span>
          </div>
        </div>
        <div className="reference-dscripton">
          <div className="first-element  d-flex justify-content-start align-items-center">
            <img src={database}/>
          </div>
          <div className="second-element">
            <span className="d-flex align-items-center justify-content-start">{t('datbase_systems')}</span>
          </div>
          <div className="fourth-element">
            <span className="d-flex align-items-center">{t('we_are_connected_to')}</span>
          </div>
        </div>
        </MDBCol>
      </MDBRow>
    </section>
    <section className="fifth-section">
      <div className="top-section">
        
        <div className="title-text d-flex justify-content-center align-items-center">
            <span>Myensign</span>
        </div>
        <div className="link-text d-flex justify-content-center align-items-center">
            <a href="https://myensign.eu/test" target="_blank">{t('open_website')}</a>
        </div>
        <div className="description-text d-flex justify-content-center align-items-center">
            <span>{t('myensig')}</span>
        </div>
      </div>
      <MDBRow className="m-0">
        <MDBCol center md="3">
          <div className="reference-dscripton pb-5">
            <div className="first-element d-flex justify-content-end align-items-center">
              <img src={designer}/>
            </div>
            <div className="second-element">
              <span className="d-flex align-items-center justify-content-end">{t('jewelery_designer')}</span>
            </div>
            <div className="third-element">
              <span className="d-flex align-items-center">{t('the_built_in_3D_jewellery')}</span>
            </div>
          </div>
          <div className="reference-dscripton">
            <div className="first-element d-flex justify-content-end align-items-center">
              <img src={catalog}/>
            </div>
            <div className="second-element">
              <span className="d-flex align-items-center justify-content-end">{t('jewelery_catalog')}</span>
            </div>
            <div className="third-element">
              <span className="d-flex align-items-center">{t('the_predefined_jewellery_can_be')}</span>
            </div>
          </div>
        </MDBCol>
        <MDBCol md="6" className="reference-card-col">
          <img src={design_element_14} className="deisgn-element-6"/>
        </MDBCol>
        <MDBCol center md="3">
        <div className="reference-dscripton pb-5">
          <div className="first-element">
            <img src={shop}/>
          </div>
          <div className="second-element">
            <span className="d-flex align-items-center justify-content-start">{t('online_shop')}</span>
          </div>
          <div className="fourth-element">
            <span className="d-flex align-items-center">{t('in_the_online_shop')}</span>
          </div>
        </div>
        <div className="reference-dscripton">
          <div className="first-element  d-flex justify-content-start align-items-center">
            <img src={discount}/>
          </div>
          <div className="second-element">
            <span className="d-flex align-items-center justify-content-start">{t('valuta')}</span>
          </div>
          <div className="fourth-element">
            <span className="d-flex align-items-center">{t('the_proprietary_currency_converter')}</span>
          </div>
        </div>
        </MDBCol>
      </MDBRow>
    </section>
    <section className="sixth-section">
      <div className="top-section">
        
        <div className="title-text d-flex justify-content-center align-items-center">
            <span>J&C World</span>
        </div>
        <div className="link-text d-flex justify-content-center align-items-center">
            <a href="https://jandc-world.ch" target="_blank">{t('open_website')}</a>
        </div>
        <div className="description-text d-flex justify-content-center align-items-center">
            <span>{t('jandc_is_an_online_store')}</span>
        </div>
      </div>
      <MDBRow className="m-0">
        <MDBCol center md="3">
          <div className="reference-dscripton pb-5">
            <div className="first-element d-flex justify-content-end align-items-center">
              <img src={toolkit}/>
            </div>
            <div className="second-element">
              <span className="d-flex align-items-center justify-content-end">{t('all_in_one_toolikit')}</span>
            </div>
            <div className="third-element">
              <span className="d-flex align-items-center">{t('jandc_provides_an_interface')}</span>
            </div>
          </div>
          <div className="reference-dscripton">
            <div className="first-element d-flex justify-content-end align-items-center">
              <img src={invoice}/>
            </div>
            <div className="second-element">
              <span className="d-flex align-items-center justify-content-end">{t('invoicing')}</span>
            </div>
            <div className="third-element">
              <span className="d-flex align-items-center">{t('the_invoicing_system_is_fully')}</span>
            </div>
          </div>
        </MDBCol>
        <MDBCol md="6" className="reference-card-col">
          <img src={design_element_8} className="deisgn-element-6"/>
        </MDBCol>
        <MDBCol center md="3">
        <div className="reference-dscripton pb-5">
          <div className="first-element">
            <img src={business}/>
          </div>
          <div className="second-element">
            <span className="d-flex align-items-center justify-content-start">{t('business_insights')}</span>
          </div>
          <div className="fourth-element">
            <span className="d-flex align-items-center">{t('sellers_are_able_to_acces')}</span>
          </div>
        </div>
        <div className="reference-dscripton">
          <div className="first-element  d-flex justify-content-start align-items-center">
            <img src={tax}/>
          </div>
          <div className="second-element">
            <span className="d-flex align-items-center justify-content-start">{t('tax_compliance')}</span>
          </div>
          <div className="fourth-element">
            <span className="d-flex align-items-center">{t('if_you_are_a_business_you')}</span>
          </div>
        </div>
        </MDBCol>
      </MDBRow>
    </section>

    
    <section className="seventh-section">
    <div className="galerie-component">
      
            <Carousel
                additionalTransfrom={0} 
                draggable={false}
                showDots={true}
                responsive={responsive}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                slidesToSlide={1}
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 1000ms ease-in-out"
                transitionDuration={1000}
                arrows={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}

            >
              
              <div className="references-card-2">
                <div className="top-section">
                  
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Alakreform</span>
                  </div>
                  <div className="link-text d-flex justify-content-center align-items-center">
                      <a href="https://videotar.rubintreka.hu" target="_blank">{t('open_website')}</a>
                  </div>
                  <div className="description-text d-flex justify-content-center align-items-start">
                      <span>{t('alakreform_was_created_for')}</span>
                  </div>
                </div>
                <div  className="third-col">
                
                  <MDBRow className="m-0">
                    <img src={design_element_13}/>
                  </MDBRow>
                </div>
              </div>
              <div className="references-card-2">
                <div className="top-section">
                  
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>e-Bikebörse</span>
                  </div>
                  <div className="link-text d-flex justify-content-center align-items-center">
                      <a href="https://e-bikeboerse.com" target="_blank">{t('open_website')}</a>
                  </div>
                  <div className="description-text d-flex justify-content-center align-items-start">
                      <span>{t('e_bikebörse_is_an')}</span>
                  </div>
                </div>
                <div  className="third-col">
                
                  <MDBRow className="m-0">
                    <img src={design_element_7}/>
                  </MDBRow>
                </div>
              </div>
              <div className="references-card">
                <div className="top-section">
                  
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Naviplan</span>
                  </div>
                  <div className="link-text d-flex justify-content-center align-items-center">
                      <a href="https://naviplan.ch" target="_blank">{t('open_website')}</a>
                  </div>
                  <div className="description-text d-flex justify-content-center align-items-start">
                      <span>{t('naviplan_is_a_website')}</span>
                  </div>
                </div>
                <div  className="first-col">
                  
                  <MDBRow className="m-0">
                    <img src={design_element_11}/>
                  </MDBRow>
                </div>
              </div>
              <div className="references-card-1">
                <div className="top-section">
                  
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Danfoss</span>
                  </div>
                  <div className="link-text d-flex justify-content-center align-items-center">
                      <a href="https://danfosscrimpersupport.com" target="_blank">{t('open_website')}</a>
                  </div>
                  <div className="description-text d-flex justify-content-center align-items-start">
                      <span>{t('this_website_includes_multiple')}</span>
                  </div>
                </div>
                <div  className="second-col">
                  
                  <MDBRow className="m-0">
                    <img src={design_element_12}/>
                  </MDBRow>
                </div>
              </div>
        
            </Carousel>
           
    </div>

    
    </section>
    </div>

  );
};

export default withTranslation()(ReferencesSection);
