// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_2 from "../../assets/media/images/Background Design Elements.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";
import design_element_9 from "../../assets/media/images/landingPagePreview.svg";
import design_element_10 from "../../assets/media/images/Mobile Image.svg";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";


const YourVisionSection = (props) => {
  
  // MULTILANGUAGE
  const { t } = i18n;

 
  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (
    <section className={pathname === 'homepage' ? "second-section-spec" : "second-section"} id="your-vision">
      <MDBContainer style={{maxWidth: "1670px"}} className="third-container">
        <img src={design_element_2} className="deisgn-element-2"/>
        <MDBRow className="m-0 px-5 pb-4" style={{height: "570px"}}>
          <MDBCol md="7" className="d-flex justify-content-start align-items-center">
            <div className="first-card">
              <img src={design_element_3} className="design-element-3"/>
              <img src={design_element_9} className="design-element-9"/>
              <img src={design_element_10} className="design-element-10"/>
            </div>
          </MDBCol>
          <MDBCol md="5" center className="">
            <div className="top-text pb-2">
              <span>
              {t('enhance_your_business')}
              </span>
              
            </div>
            <div className="middle-text pb-2">
              <span>
              {t('your_vision_our_code')}
              </span>
              <img src={design_element_4} height='86'/>
            </div>
            <div className="bottom-text">
              <span>
              {t('in_today_text')}
              </span>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
};

export default withTranslation()(YourVisionSection);
