// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_2 from "../../assets/media/images/Background Design Elements.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";
import design_element_9 from "../../assets/media/images/landingPagePreview.svg";
import design_element_10 from "../../assets/media/images/Mobile Image.svg";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";


const YourVisionSectionSpecielPrivateRespo = (props) => {
  
  // MULTILANGUAGE
  const { t } = i18n;

 
  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (
    <section className="second-section" id="your-vision-respo">
       
        <MDBRow className="m-0 px-3 pb-4">
          <MDBCol md="12" center className="">
            <div className="top-text pb-2">
              {t('internet_and_web')}
            </div>
            <div className="middle-text pb-4">
              <span>
              {t('expand_your_business')}
              </span>
            </div>
            <div className="bottom-text">
              <span>
              {t('these_days_effective_demand')}
              </span><br/><br/>
              <span>
              {t('this_is_the_time')}
              </span><br/><br/>
              <span>
              {t('have_a_new_website')} 
              </span>
            </div>
           
          </MDBCol>
          <MDBCol md="12" className="design-col px-2">
            <div className="first-card">
              <img src={design_element_3} className="design-element-3"/>
              <img src={design_element_9} className="design-element-9"/>
              <img src={design_element_10} className="design-element-10"/>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="m-0 px-3 pb-4">
        <MDBCol md="12" center className="">
          <div className="bottom-text pb-5">
            <span>{t('yes_we_know_that_the_way')}</span><br/><br/>
            <span>{t('we_understand_you')}</span><br/><br/>
            <span>{t('yeah_thats_true')}</span><br/><br/>
            <span>{t('what_we_offer_is_a_technoblabla')}</span><br/><br/>
            <span>{t('the_advantage_of_choosing')} </span>
            <span style={{fontWeight: 700}}>{t('technoblabla_free_partnership')} </span>
            <span>{t('we_first_develop_a_website')} </span>
            <span style={{fontWeight: 700, textDecoration: "underline"}}>{t('we')}</span>
            <span>{t('have_a_new_idea_we_discuss_it')}</span><br/><br/>
            <span>{t('two_example_packages_to_demonstrate')}</span><br/><br/>
            <div style={{maxWidth: "700px"}}>
                <span style={{fontWeight: 700}}>{t('website_package')}</span><br/><br/>
                <span>{t('you_will_have_your_own_website')}</span>
                <span>{t('we_create_you_a_website_with')}</span>
                <span>{t('main_page')}</span>
                <span>{t('products_services')}</span>
                <span>{t('about_you_or_your_team')}</span>
                <span>{t('contact')}</span>
                <span>{t('imprint_impressum')}</span>
                <span>{t('with_our_lawyer_we_guarantee')}</span>
                <span>{t('cost_3000_EUR')}</span><br/><br/>
                <span style={{fontWeight: 700}}>{t('webshop_package')}</span><br/><br/>
                <span>{t('everything_from_the')}</span>
                <span>{t('you_can_add_your_own_products')}</span>
                <span>{t('customers_can_buy_your_products')}</span>
                <span>{t('we_create_invoices')}</span>
                <span>{t('maximum_of_20_products')}</span>
                <span>{t('twice_a_month_we_manually')}</span>
                <span>{t('cost_7000_EUR')}</span>
            </div>
          </div>
        </MDBCol>
        </MDBRow>
        
          <div className="tech-footer-contact-text px-3">
                <span>{t('ask_for_a_first_free_consultation_and_make')}</span>
          </div>
          <div className="tech-footer-button pb-5">
            <button onClick={() => {
                        var element = document.getElementById('contact-us-respo');
                        var headerOffset = 59;
                        var elementPosition = element.getBoundingClientRect().top;
                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                      
                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth"
                        })
                      }}>
              <span>{t('contact_us')}</span>
            </button>
          </div>
    </section>
  );
};

export default withTranslation()(YourVisionSectionSpecielPrivateRespo);
