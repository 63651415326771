import React from 'react'
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'
import MainPage from '../pages/MainPage';
import HomePage from '../pages/HomePage';
import HomePagePrivate from '../pages/HomePagePrivate';
import HomePageTechCompanies from '../pages/HomePageTechCompanies';
import HomePageAgencies from '../pages/HomePageAgencies';
import HomePageStartups from '../pages/HomePageStartups';
import TeamPage from '../pages/TeamPage';
import UserAgreement from "../../About/UserAgreement";
import CookiePolicy from "../../About/CookiePolicy";
import Impressum from "../../About/Impressum";


function AnimatedRoutes() {

    const location = useLocation();
    return (
        <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="" element={<Navigate to='/mainpage' />} />
            <Route path="/mainpage" element={<MainPage />} />
            <Route path="/homepage" element={<HomePage />} />
            <Route path="/homepage/private" element={<HomePagePrivate />} />
            <Route path="/homepage/tech" element={<HomePageTechCompanies />} />
            <Route path="/homepage/agencies" element={<HomePageAgencies />} />
            <Route path="/homepage/startups" element={<HomePageStartups />} />
            <Route path="/teampage" element={<TeamPage />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/useragreement" element={<UserAgreement />} />
            <Route path="/cookiepolicy" element={<CookiePolicy />} />
        </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes
