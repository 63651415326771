// react imports
import React, { useContext, useState, useEffect } from "react";
import { Outlet, Link, NavLink, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import styles from '../../assets/scss/modules/layout.module.scss';
import AppContext from '../../AppContext';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';

// MDBOOTSTRAP IMPORTS
import { MDBContainer,  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink, } from "mdb-react-ui-kit";

// IMPORT LAYOUT COMPONENTS
//import Footer from "./footer/Footer";
//import Header from "./header/Header";
import NavbarComponent from "../navbarcomponent/NavbarComponent"
// 3RD PARTY IMPORTS
import Moment from 'moment';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



function Layout({ children }) {
  const context = useContext(AppContext);
  const { search } = useLocation();

  
  
  // STATES

  // react router navigation
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // MULTILANGUAGE
  const { t } = i18n;


  // events
  const loginEvent = (email, password) => {

    context.interAxios.post('/auth/login', {password: password, email: email}, function(response) {
      context.onSignIn(response.data);

    }, function(error) {
      alert("hibás jelszó");
    });

  };

  const showLogoutClicked = () => {
    context.onLogout();
    //navigate('/marketplace');
  }
  return (
    <MDBContainer fluid className='p-0' >
      <main>
      {children}
      </main>
    </MDBContainer>
  );
}

export default withTranslation()(Layout);
