// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_5 from "../../assets/media/images/Background Design Elements orange.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";

import javascript from "../../assets/media/images/javascript-logo-svgrepo-com.svg";
import springboot from "../../assets/media/images/springio-icon.svg";
import reactjs from "../../assets/media/images/React-icon.svg";
import java from "../../assets/media/images/java-svgrepo-com.svg";
import neo4j from "../../assets/media/images/neo4j.svg";
import lottiefiles from "../../assets/media/images/lottiefiles-logo.svg";
import invision from "../../assets/media/images/InVision_logo.svg";
import sketch from "../../assets/media/images/Sketch-logo-light.svg";
import figma from "../../assets/media/images/Figma_logo.svg";
import kotlin from "../../assets/media/images/Kotlin.png";
import unity from "../../assets/media/images/Unity.png";
import swift from "../../assets/media/images/Swift.png";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";


const TechStackSection = (props) => {

  
  // MULTILANGUAGE
  const { t } = i18n;


  return (
    <section className="third-section" id="tech-stack">
      <MDBContainer style={{maxWidth: "1670px"}} className="third-container">
      <img src={design_element_5} className="deisgn-element-5"/>
      <MDBRow className="m-0 py-4">
        
        <MDBCol md="7" center className="stack-col-right text-cnter p-0">
            <img src={design_element_3} className="design-element-3"/>
            <img src={design_element_4} className="design-element-4"/>
            <div className="technologie-1">
              <div className="javascript">
                <img src={javascript}/>
                <span>Javascript</span>
              </div>
              <div className="kotlin">
                <img src={kotlin}/>
              </div>
              <div className="unity">
                <img src={unity}/>
              </div>
            </div>
            <div className="technologie-4">
              <div className="lottiefiles">
                <img src={lottiefiles}/>
              </div>
              <div className="invision">
                <img src={invision}/>
              </div>
            </div>
            <div className="technologie-2">
              <div className="springboot">
                <img src={springboot}/>
                <span>Spring</span>
              </div>
              <div className="reactjs">
                <img src={reactjs}/>
                <span>React JS</span>
              </div>
            </div>
            <div className="technologie-3">
              <div className="java">
                <img src={java}/>
                <span>Java</span>
              </div>
              <div className="neo4j">
                <img src={neo4j}/>
                <span>Neo4J</span>
              </div>
            </div>
            <div className="technologie-5">
              <div className="sketch">
                <img src={sketch}/>
              </div>
              <div className="figma">
                <img src={figma}/>
              </div>
              <div className="swift">
                <img src={swift}/>
              </div>
            </div>
        </MDBCol>
        <MDBCol md="5" center className="ps-5">
          <div className="top-text pb-2">
            <span>
            {t('cutting_edge')}
            </span>
          </div>
          <div className="middle-text pb-2">
            <span>
            {t('tech_stack')}
            </span>
          </div>
          <div className="bottom-text">
            <span>
            {t('we_harness')}
            </span>
          </div>
        </MDBCol>
      </MDBRow>
      </MDBContainer>
    </section>
  );
};

export default withTranslation()(TechStackSection);
