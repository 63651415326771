// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from 'framer-motion'


// CUSTOM COMPONENTS
import HomePageResponsiv from './respo/HomePageResponsiv'
import HomePageDesktop from './desktop/HomePageDesktop'


const HomePage = (props) => {


  // STATES
  const [maxDevice, setMaxDevice] = useState(true);
  useEffect(() => {
      
    setMaxDevice(window.matchMedia('(max-device-width: 992px)').matches)
    
 }, [window.matchMedia('(max-device-width: 992px)').matches]);
 

  return (
    <>
        <motion.div intial={{width: 0}}
                    animate={{width: "100%"}}
                    exit={{x: window.innerWidth, transition: { duration: 0.6 }}}
                    className="d-none d-lg-block"
        >
					<HomePageDesktop/>
        </motion.div>
				<div className="d-lg-none d-block">
					<HomePageResponsiv/>
				</div>
    </>


  );
};

export default HomePage;
