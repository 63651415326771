import React from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';

// LAYOUT, PAGE IMPORTS
import Layout from './components/layout/Layout';
import MainPage from './components/pages/MainPage';
import HomePage from './components/pages/HomePage';
import HomePagePrivate from './components/pages/HomePagePrivate';
import HomePageTechCompanies from './components/pages/HomePageTechCompanies';
import HomePageAgencies from './components/pages/HomePageAgencies';
import HomePageStartups from './components/pages/HomePageStartups';
import TeamPage from './components/pages/TeamPage';
import UserAgreement from "./About/UserAgreement";
import CookiePolicy from "./About/CookiePolicy";
import Impressum from "./About/Impressum";
import AnimatedRoutes from './components/animatedRoutes/AnimatedRoutes';

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Layout>
          <AnimatedRoutes/>
        </Layout>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
