// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import AppContext from '../../AppContext';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';
import NavbarComponent from "../navbarcomponent/NavbarComponent"
import EmailForm from "../common/EmailForm"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import design_element_5 from "../../assets/media/images/Responsiv/Background Design Elements right.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";

import javascript from "../../assets/media/images/javascript-logo-svgrepo-com.svg";
import springboot from "../../assets/media/images/springio-icon.svg";
import reactjs from "../../assets/media/images/React-icon.svg";
import java from "../../assets/media/images/java-svgrepo-com.svg";
import neo4j from "../../assets/media/images/neo4j.svg";
import lottiefiles from "../../assets/media/images/lottiefiles-logo.svg";
import invision from "../../assets/media/images/InVision_logo.svg";
import sketch from "../../assets/media/images/Sketch-logo-light.svg";
import figma from "../../assets/media/images/Figma_logo.svg";
import kotlin from "../../assets/media/images/Kotlin.png";
import unity from "../../assets/media/images/Unity.png";
import swift from "../../assets/media/images/Swift.png";


import { MDBRow, MDBCol, MDBCard, MDBCarousel,
  MDBCarouselItem, MDBContainer
   } from "mdb-react-ui-kit";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



// CUSTOM COMPONENTS



const TechStackSectionRespo = (props) => {

  //MDBAlert
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // REACT ROUTER PARAMS - for processing url
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  

  // STATES
 
  // MULTILANGUAGE
  const { t } = i18n;

  const responsive = {
    mobile_1: {
        breakpoint: { max: 992, min: 930 },
        items: 4,
        partialVisibilityGutter: 9
    },
    mobile_2: {
        breakpoint: { max: 930, min: 600 },
        items: 3,
        partialVisibilityGutter: 9
    },
    mobile_3: {
        breakpoint: { max: 599, min: 350 },
        items: 2,
        partialVisibilityGutter: 9
    },
    mobile_4: {
        breakpoint: { max: 350, min: 160 },
        items: 1,
        partialVisibilityGutter: 9
    },
    
  };
 
  return (
    <>
    <section className="third-section" id="tech-stack-respo">
      
      <MDBRow className="m-0 px-3 py-4">
        <MDBCol md="12" center className="">
          <div className="top-text pb-2">
            <span>
            {t('cutting_edge')}
            </span>
          </div>
          <div className="middle-text pb-2">
            <span>
            {t('tech_stack')}
            </span>
          </div>
          <div className="bottom-text">
            <span>
            {t('we_harness')}
            </span>
          </div>
        </MDBCol>
        <MDBCol md="12" center className="stack-col-right d-flex justify-content-end text-right">
            <img src={design_element_5} className="deisgn-element-5"/>
            <img src={design_element_4} className="design-element-4"/>
            
        </MDBCol>
       
      </MDBRow>
      <MDBRow style={{marginTop: "-265px"}}>
            <div className="technologie-1">
                <MDBContainer className="p-0">
                    <Carousel
                        additionalTransfrom={0} 
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        infinite={true}
                        slidesToSlide={1}
                        centerMode={false}
                        autoPlay={true}
                        autoPlaySpeed={12050}
                        keyBoardControl={false}
                        customTransition="transform 12000ms ease-in-out"
                        transitionDuration={12000}
                        containerClass="carousel-container-slide"
                        removeArrowOnDeviceType={["mobile_4", "mobile_3", "mobile_2", "mobile_1"]}
                    >
                        <div className="javascript">
                            <img src={javascript}/>
                            <span>Javascript</span>
                        </div>
                        <div className="kotlin">
                            <img src={kotlin}/>
                        </div>
                        <div className="unity">
                            <img src={unity}/>
                        </div>
                        <div className="lottiefiles">
                            <img src={lottiefiles}/>
                        </div>
                    </Carousel>
                </MDBContainer>
            </div>
            <div className="technologie-2">
                <MDBContainer className="p-0">
                <Carousel
                        additionalTransfrom={0} 
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        infinite={true}
                        slidesToSlide={1}
                        centerMode={false}
                        autoPlay={true}
                        autoPlaySpeed={11050}
                        keyBoardControl={false}
                        customTransition="transform 11000ms ease-in-out"
                        transitionDuration={11000}
                        containerClass="carousel-container-slide"
                        removeArrowOnDeviceType={["mobile_4", "mobile_3", "mobile_2", "mobile_1"]}
                    >
                       <div className="springboot">
                        <img src={springboot}/>
                        <span>Spring</span>
                        </div>
                        <div className="reactjs">
                        <img src={reactjs}/>
                        <span>React JS</span>
                        </div>
                        <div className="invision">
                        <img src={invision}/>
                        </div>
                        <div className="sketch">
                        <img src={sketch}/>
                        </div>
                    </Carousel>
                </MDBContainer>
            </div>
            <div className="technologie-3">
                <MDBContainer className="p-0">
                <Carousel
                        additionalTransfrom={0} 
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        infinite={true}
                        slidesToSlide={1}
                        centerMode={false}
                        autoPlay={true}
                        autoPlaySpeed={10050}
                        keyBoardControl={false}
                        customTransition="transform 10000ms ease-in-out"
                        transitionDuration={10000}
                        containerClass="carousel-container-slide"
                        removeArrowOnDeviceType={["mobile_4", "mobile_3", "mobile_2", "mobile_1"]}
                    >
                       <div className="java">
                        <img src={java}/>
                        <span>Java</span>
                        </div>
                        <div className="neo4j">
                        <img src={neo4j}/>
                        <span>Neo4J</span>
                        </div>
                        <div className="figma">
                        <img src={figma}/>
                        </div>
                        <div className="swift">
                        <img src={swift}/>
                        </div>
                    </Carousel>
                </MDBContainer>
            </div>
      </MDBRow>
    </section>
    </>


  );
};

export default withTranslation()(TechStackSectionRespo);
