// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import AppContext from '../../AppContext';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';
import NavbarComponentTeamPage from "../navbarcomponent/NavbarComponentTeamPage"
import profile_Boda from "../../assets/media/images/profile_Boda_2.jpg";
import profile_Pasztuhov from "../../assets/media/images/profile_Pasztuhov.jpg";
import profile_Szaniszlo from "../../assets/media/images/profile_Szaniszlo.jpg";
import profile_Beni from "../../assets/media/images/Beni.jpeg";
import profile_Dani from "../../assets/media/images/profile_Dani.jpg";
import profile_user from "../../assets/media/images/user.png";

import orange_circle from "../../assets/media/images/Design Element - Circle.svg";
import cover_shadow from "../../assets/media/images/Cover Image Shadow.svg";
import logo_big from "../../assets/media/images/logo_big.svg";
import design_element_1 from "../../assets/media/images/Background Design element - Circles.svg";



import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



// CUSTOM COMPONENTS



const TeamPage = (props) => {

  //MDBAlert
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // REACT ROUTER PARAMS - for processing url
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  

  // STATES
  const [isCard, setIsCard] = useState(true);
  const [imageHeight, setImageHeight] = useState(document.getElementById("backgroundImage")?.height);
  const [imageHeight_2, setImageHeight_2] = useState(document.getElementById("backgroundImage_2")?.height);
  
  // MULTILANGUAGE
  const { t } = i18n;

  useEffect(() => {
    
    
  }, []);

  useEffect(() => {
      
      setTimeout(() => {
        setImageHeight_2(document.getElementById("backgroundImage_2")?.height)
      },500)
      setTimeout(() => {
        setImageHeight(document.getElementById("backgroundImage")?.height)
      },500)
      
   }, []);
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  const handleWindowSizeChange = () => {
    setImageHeight(document.getElementById("backgroundImage")?.height);
    setImageHeight_2(document.getElementById("backgroundImage_2")?.height);
  };
  useEffect(() => {
    setTimeout(() => {
      context.onSetIsScroll(false)
    },1000)
    
    
  }, []);
  

  return (
    <div className="team-div" >
      <MDBContainer style={{maxWidth: "1920px"}}>
        <div>
        <div className="main-navbar" id="main-navbar-id">
          <NavbarComponentTeamPage/>
        </div>
          <MDBRow className="m-0  d-flex justify-content-end align-items-center teampage-row">
            <MDBCol xl="7" lg="8" md="12" center className="top-col-left">
            
            <div className="top-text pb-2">
              <span>
              {t('our_team_leaders')}
              </span>
            </div>
            <div className="middle-text">
              <span>
              {t('our_team_leaders_keep')}
              </span>
            </div>
            <MDBContainer>
              <MDBRow className="m-0 d-flex justify-content-center align-items-center">
              
                <MDBCol lg="4" md="4" sm="6" size="12" center className="px-2 ">
                  <div className="team-1 ">
                    <img src={profile_Boda} className="profile-image"/>
                    <div className="member-details">
                      <div class="h5 dark-text dark-border-bottom">
                        Balazs Boda
                      </div>
                      <div className="position">
                      <span>{t('balazs')}</span> 
                      </div>
                    </div>
                    <div className="details">
                      <p>
                      {t('he_is_webfrontend_development_manager')}
                      </p>
                    </div>
                  </div>
                </MDBCol>
                
                <MDBCol lg="4" md="4" sm="6" size="12" center className="px-2 ">
                  <div className="team-1 ">
                    <img src={profile_Pasztuhov} className="profile-image"/>
                    <div className="member-details">
                        <div class="h5 dark-text dark-border-bottom">
                          Daniel Pasztuhov
                        </div>
                        <div className="position">
                        <span>{t('dani')}</span>
                        </div>
                      </div>
                    <div className="details">
                      <p>
                        {t('he_started_programming_when_he_was_a_child')}
                      </p>
                    </div>
                  </div>
                </MDBCol>

                <MDBCol lg="4" md="4" sm="6" size="12" center className="px-2 ">
                  <div className="team-1 ">
                    <img src={profile_Szaniszlo} className="profile-image"/>
                    <div className="member-details">
                      <div class="h5 dark-text dark-border-bottom">
                        Zoltan Szaniszlo
                      </div>
                      <div className="position">
                      <span>{t('zoli')}</span> 
                      </div>
                    </div>
                    <div className="details">
                      <p>
                        {t('he_is_the_driving_force_behind')}
                      </p>
                    </div>
                  </div>
                </MDBCol>

                <MDBCol lg="4" md="4" sm="6" size="12" center className="px-2 ">
                  <div className="team-2 ">
                    <img src={profile_Dani} className="profile-image"/>
                    <div className="member-details">
                        <div class="h5 dark-text dark-border-bottom">
                          Daniel Varga
                        </div>
                        <div className="position">
                        <span>marketing manager</span>
                        </div>
                      </div>
                    <div className="details">
                      <p>
                      {t('the_yougest_but_also_the')}
                      </p>
                    </div>
                  </div>
                </MDBCol>
                
                <MDBCol lg="4" md="4" sm="6" size="12" center className="px-2 ">
                  <div className="team-2 ">
                    <img src={profile_user} className="profile-image"/>
                    <div className="member-details">
                      <div class="h5 dark-text dark-border-bottom">
                        Christoph Buchbauer
                      </div>
                      <div>
                      <div className="position-distri justify-content-center">
                      <span>{t('distributors')}</span><br/>
                      </div>
                      <div className="position-distri justify-content-center m-0">
                      <span>Tel.: +49 172 3236227</span>
                      </div>
                      </div>
                    </div>
                    <div className="details">
                      <p>
                        ...
                      </p>
                    </div>
                  </div>
                </MDBCol>
                
                <MDBCol lg="4" md="4" sm="6" size="12" center className="px-2 ">
                  <div className="team-2 ">
                    <img src={profile_Beni} className="profile-image"/>
                    <div className="member-details">
                      <div class="h5 dark-text dark-border-bottom">
                        Benjamin Juhasz
                      </div>
                      <div className="position">
                        <span>senior UX / UI designer</span>
                      </div>
                    </div>
                    <div className="details">
                      <p>
                      {t('he_is_constantly_expanding_interaction')}
                      </p>
                    </div>
                  </div>
                </MDBCol>
              
              </MDBRow>
            </MDBContainer>
            </MDBCol>
            <MDBCol xl="5" md="4" className="d-flex justify-content-start align-items-center interb2b-logo-col">
              <div className="d-none d-lg-block">
                <img src={design_element_1} className="design-element-1"  />
              </div>
              <div className="d-none d-xl-block">
              <img src={logo_big}  className="big-logo-img"/>
              <img src={cover_shadow}  height='78' className="cover-shadow-img"/>
              <img src={orange_circle} className="orange-circle-img"/>
              </div>
            </MDBCol>
          </MDBRow>
         
        </div>
      </MDBContainer>
    </div>


  );
};

export default withTranslation()(TeamPage);
