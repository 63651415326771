// react imports
import React, { useContext, useState, useEffect } from "react";
import { Outlet, Link, NavLink, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import styles from '../../assets/scss/modules/layout.module.scss';
import AppContext from '../../AppContext';
import i18n from '../../i18n';
import Flags from 'country-flag-icons/react/3x2'

// MDBOOTSTRAP IMPORTS
import { MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse, } from "mdb-react-ui-kit";

// IMPORT LAYOUT COMPONENTS
//import Footer from "./footer/Footer";
//import Header from "./header/Header";
import HeaderLanguageSelector from "./language-selector/HeaderLanguageSelector";

import logo from '../../assets/media/images/logo.svg'
import logo_2 from "../../assets/media/images/Responsiv/logo.svg";
import back_arrow from '../../assets/media/images/back-arrow.png'
import hamburger from '../../assets/media/images/Responsiv/menu.svg'
import close from '../../assets/media/images/Responsiv/close.svg'
import youtube from '../../assets/media/images/yt_logo_mono_dark.png'

// 3RD PARTY IMPORTS
import Moment from 'moment';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



function NavbarComponent(props) {
  const context = useContext(AppContext);
  const { search } = useLocation();

  
  
  // STATES
  const [showBasic, setShowBasic] = useState(false);
  const [activeItem, setActiveItem] = useState(context.getState()?.activeItem);
  
  // react router navigation
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // MULTILANGUAGE
  const { t } = i18n;

  
  const [language, setLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'EN' : 'DE');
  // EVENTS
  const getLanguage = (language) => {
    setLanguage(language)
    if(language === 'EN'){
      context.toEnglish();
      context.onRenderTrue();
    }else{
      context.toGerman();
      context.onRenderTrue();
    }
  };

  useEffect(() => {
    if(cookies.get('mylanguage')?.language === 'en'){
      setLanguage('EN')
    }else{
      setLanguage('DE')
    }
    context.onRender();
  }, [context.getState()?.isRender]);


  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (<>
          <div className=" fixed-main-navbar d-flex justify-content-center d-none d-lg-flex">
            <MDBNavbar expand='lg' light  className={context.getState()?.isScroll ? "scroll" : ""}>
            <MDBContainer style={{maxWidth: "1600px"}}>
              <div className="d-none d-lg-block">
                {pathname === "teampage" ? 
                <MDBNavbarBrand onClick={() => {
                              if(context.getState()?.pathname !== undefined){
                                navigate(context.getState()?.pathname === "homepage" ? '/homepage' : '/homepage/' + context.getState()?.pathname);
                              }else{
                                navigate(-1)
                              }
                              
                              context.onSetIsScroll(false); 
                              context.onSetActiveItem("1");}} >
                  <MDBNavbarLink active={context.getState()?.activeItem === "1"}>
                    <img
                        src={logo}
                        height='60'
                        alt=''
                        loading='lazy'
                    />
                  </MDBNavbarLink>
                </MDBNavbarBrand> : 

                <MDBNavbarBrand onClick={() => {navigate('/mainpage'); context.onSetIsScroll(false); context.onSetActiveItem("1");}} >
                  <MDBNavbarLink active={context.getState()?.activeItem === "1"}>
                    {pathname !== "mainpage" && (
                      <img
                        src={back_arrow}
                        height='14'
                        alt=''
                        loading='lazy'
                      />
                    )}
                  
                    <img
                        src={logo}
                        height='60'
                        alt=''
                        loading='lazy'
                    />
                  </MDBNavbarLink>
                </MDBNavbarBrand>}
              </div>

                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <MDBNavbarItem link childTag='button' onClick={() => getLanguage('EN')}>
                      <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'en'} href='#'  className="en-item">en</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem link childTag='button' onClick={() => getLanguage('DE')} className="de-item">
                      <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'de'} href='#' className="de-item">de</MDBNavbarLink>
                    </MDBNavbarItem>
                  </div>
                  <div className="d-flex justify-content-end align-items-center" style={{width: "100%"}}>
                    {pathname !== "teampage" &&  !props.isMainpage && ( 
                      <>
                        <MDBNavbarItem  onClick={() => {
                                                    context.onSetNavChange(true)
                                                    window.scrollTo({ top: 0, behavior: 'smooth' });  
                                                    context.onSetActiveItem("1");
                                                    if(pathname === "teampage"){
                                                      navigate(context.getState().pathname !== undefined ?  '/homepage/' + context.getState().pathname :  '/homepage')
                                                    }
                                                    context.onSetIsScroll(false);
                                                    setTimeout(() => {context.onSetNavChange(false)},600)}}
                                                    >
                          <MDBNavbarLink  active={context.getState()?.activeItem === "1"}>Home</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem  onClick={() => { 
                                      context.onSetNavChange(true)
                                      var element = document.getElementById(pathname === "tech" ? 'tech-stack' : 'your-vision');
                                      var headerOffset = -200;
                                      var elementPosition = element.getBoundingClientRect().top;
                                      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                      context.onSetActiveItem("5");
                                      window.scrollTo({
                                          top: offsetPosition,
                                          behavior: "smooth"
                                        }) 
                                      setTimeout(() => {context.onSetNavChange(false)},600)
                                      }}>
                          <MDBNavbarLink  active={context.getState()?.activeItem === "5"}>{pathname === "tech" ? t('navbar.tech_stack') : t('navbar.your_vision')}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem onClick={() => {
                                        context.onSetNavChange(true)
                                        var element = document.getElementById(pathname === "tech" ? 'your-vision' : 'tech-stack');
                                        var headerOffset =85;
                                        var elementPosition = element.getBoundingClientRect().top;
                                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                        context.onSetActiveItem("2");
                                        window.scrollTo({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        })
                                        setTimeout(() => {context.onSetNavChange(false)},600)
                                        }}>
                          <MDBNavbarLink  active={context.getState()?.activeItem === "2"}>{pathname === "tech" ?  t('navbar.your_vision') : t('navbar.tech_stack')}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem onClick={() => {
                                        context.onSetNavChange(true)
                                        var element = document.getElementById('technologie-part');
                                        var headerOffset = 85;
                                        var elementPosition = element.getBoundingClientRect().top;
                                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                        context.onSetActiveItem("4");
                                        window.scrollTo({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        })
                                        setTimeout(() => {context.onSetNavChange(false)},600)
                                        }}>
                          <MDBNavbarLink  active={context.getState()?.activeItem === "4"}>{t('navbar.our_technologies')}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem  onClick={() => { 
                                        context.onSetNavChange(true)
                                        var element = document.getElementById('about-us');
                                        var headerOffset = 95;
                                        var elementPosition = element.getBoundingClientRect().top;
                                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                        context.onSetActiveItem("6");
                                        window.scrollTo({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        }) 
                                        setTimeout(() => {context.onSetNavChange(false)},600)
                                        }}>
                          <MDBNavbarLink  active={context.getState()?.activeItem === "6"}>{t('navbar.about_us')}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem  onClick={() => { 
                                        context.onSetNavChange(true)
                                        var element = document.getElementById('references');
                                        var headerOffset = 59;
                                        var elementPosition = element.getBoundingClientRect().top;
                                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                        context.onSetActiveItem("7");
                                        window.scrollTo({
                                            top: offsetPosition,
                                            behavior: "smooth"
                                        })
                                        setTimeout(() => {context.onSetNavChange(false)},600) 
                                        }}>
                          <MDBNavbarLink  active={context.getState()?.activeItem === "7"}>{t('navbar.referenzen')}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem >
                          <MDBNavbarLink  active={context.getState()?.activeItem === "8"} href="http://youtube.com/@interb2b" target="_blank">
                            <img
                                src={youtube}
                                height='30'
                                alt=''
                                loading='lazy'
                            />
                          </MDBNavbarLink>
                        </MDBNavbarItem>
                      </>
                    )}
                  </div>
                </div>
                </MDBNavbarNav>
            </MDBContainer>
            </MDBNavbar>
          </div>
          <div className=" sidenav-bottom-respo d-block d-lg-none">
            <div className="d-flex align-items-center justify-content-between">
             
                <div onClick={() => navigate('/mainpage')} style={{marginLeft: "28px", marginTop: "20px", minHeight: "60px"}}>
                  {window.scrollY < 50 && (<>
                    <img
                    src={back_arrow}
                    height='14'
                    alt=''
                    loading='lazy'
                  />
                    <img src={logo_2} height='60' className="respo-logo"/>
                    </> )} 
                </div>
               
                <MDBBtn onClick={() => setShowBasic(!showBasic)} className={window.scrollY > 450 ? "sidenavButton scroll" : "sidenavButton"}>
                  <img src={hamburger} />
                </MDBBtn>
            
            </div> 
            <MDBSideNav isOpen={showBasic} absolute right getOpenState={(e) => setShowBasic(e)}>
            <MDBSideNavMenu>
            <div className="d-flex justify-content-between align-items-center pt-4 px-3">
              <div className="d-flex justify-content-start align-items-center language-menu-list">
                <MDBSideNavItem link childTag='button' onClick={() => getLanguage('EN')}>
                  <MDBSideNavLink active={cookies.get('mylanguage')?.language === 'en'} href='#'  className="en-item">en</MDBSideNavLink>
                </MDBSideNavItem>
                <MDBSideNavItem link childTag='button' onClick={() => getLanguage('DE')} className="de-item">
                  <MDBSideNavLink active={cookies.get('mylanguage')?.language === 'de'} href='#' className="de-item">de</MDBSideNavLink>
                </MDBSideNavItem>
              </div>
              <div className="d-flex justify-content-start align-items-center">
              <MDBBtn onClick={() => setShowBasic(!showBasic)} className="close-button">
                  <img src={close} />
                </MDBBtn>
              </div>
            </div>
            <div className="side-menu-list">
              <div style={{width: "100%"}}>
              <MDBSideNavItem >
                <MDBSideNavLink active={context.getState()?.activeItemRespo === "1"}  
                                onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });  context.onSetActiveItemRespo("1"); setTimeout(() => {
                                  setShowBasic(!showBasic)
                                },900)}}>
                 <img src={logo} style={{marginRight: "10px"}}/> Home
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink active={context.getState()?.activeItemRespo === "2"} 
                                onClick={() => {
                                    var element = document.getElementById('your-vision-respo');
                                    var headerOffset = 55;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                  
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    });
                                    context.onSetActiveItemRespo("2"); setTimeout(() => {
                                      setShowBasic(!showBasic)
                                    },900)}}>
                  
                  {t('navbar.your_vision')}
                </MDBSideNavLink>
              </MDBSideNavItem>
              
              
              <MDBSideNavItem>
                <MDBSideNavLink active={context.getState()?.activeItemRespo === "3"} 
                                onClick={() => { var element = document.getElementById('tech-stack-respo');
                                    var headerOffset = 55;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                  
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    }); context.onSetActiveItemRespo("3"); setTimeout(() => {
                                      setShowBasic(!showBasic)
                                    },900)}}>
                  
                  {t('navbar.tech_stack')}
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink active={context.getState()?.activeItemRespo === "4"} 
                                onClick={() => { var element = document.getElementById('technologie-part-respo');
                                    var headerOffset = 55;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                  
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    }); context.onSetActiveItemRespo("4"); setTimeout(() => {
                                      setShowBasic(!showBasic)
                                    },900)}}>
                  
                  {t('navbar.our_technologies')}
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink active={context.getState()?.activeItemRespo === "5"} 
                                onClick={() => { var element = document.getElementById('about-us-respo');
                                    var headerOffset = 55;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                  
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    }); context.onSetActiveItemRespo("5"); setTimeout(() => {
                                      setShowBasic(!showBasic)
                                    },900)}}>
                  
                  {t('navbar.about_us')}
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink active={context.getState()?.activeItemRespo === "6"} 
                                onClick={() => { var element = document.getElementById('references-respo');
                                    var headerOffset = 55;
                                    var elementPosition = element.getBoundingClientRect().top;
                                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                  
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: "smooth"
                                    }); context.onSetActiveItemRespo("6"); setTimeout(() => {
                                      setShowBasic(!showBasic)
                                    },900)}}>
                  
                  {t('navbar.referenzen')}
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem >
                <MDBSideNavLink  active={context.getState()?.activeItem === "8"} href="http://youtube.com/@interb2b" target="_blank">
                  <img
                      src={youtube}
                      height='30'
                      alt=''
                      loading='lazy'
                  />
                </MDBSideNavLink>
              </MDBSideNavItem>
             
              </div>
            </div>
            </MDBSideNavMenu>
            </MDBSideNav>
          </div>
         </>);
}

export default NavbarComponent;
