// react imports
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import NavbarComponent from "../navbarcomponent/NavbarComponent"
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import orange_circle from "../../assets/media/images/Design Element - Circle.svg";
import orange_circle_tech from "../../assets/media/images/Orange circle.png";
import cover_shadow from "../../assets/media/images/Cover Image Shadow.svg";
import logo_big from "../../assets/media/images/interb2b_2.svg";
import design_element_1 from "../../assets/media/images/Background Design element - Circles.svg";
import blue_background from "../../assets/media/images/landing_bg.png";
import animation_1e from "../../assets/media/videos/1_end.json";
import animation_2e from "../../assets/media/videos/2_end.json";
import animation_3e from "../../assets/media/videos/3_end.json";
import animation_4e from "../../assets/media/videos/4_end.json";



import moment from 'moment';
import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";

const FirstSection = (props) => {
  

  // STATES
  const [imageHeight, setImageHeight] = useState(document.getElementById("backgroundImage")?.height);
  const [pathName, setPathName] = useState(window.location.pathname.split("/")[window.location.pathname.split("/").length-1])
  // MULTILANGUAGE
  const { t } = i18n;
  const playerRef_2e = useRef();
  useEffect(() => {
    
    
  }, []);

  useEffect(() => {

      setTimeout(() => {
        setImageHeight(document.getElementById("backgroundImage")?.height)
      },500)
      
   }, []);
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  useEffect(() => {
    setPathName(window.location.pathname.split("/")[window.location.pathname.split("/").length-1])
    
  }, [window.location.pathname.split("/")[window.location.pathname.split("/").length-1]]);

  const handleWindowSizeChange = () => {
    setImageHeight(document.getElementById("backgroundImage")?.height);
  };

  return (
    <section className="first-section" >
        <img src={blue_background} className="blue-background" id="backgroundImage" style={{marginTop: imageHeight  !== undefined  && imageHeight > 1100 ? 1100 - imageHeight : 0}}/>
        <MDBContainer style={{maxWidth: "1670px"}}>
        <div className="px-5">
        <div className="main-navbar" id="main-navbar-id">
          <NavbarComponent setLoading={props.setLoading}/>
        </div>
          <MDBRow className="m-0 px-5 d-flex justify-content-end align-items-center" style={{height: "500px", paddingTop: "150px"}}>
            <MDBCol md="6" className="middle-col-left  d-flex justify-content-start align-items-center">
              <div>
              <MDBRow className="m-0 py-4" style={{maxWidth: "610px"}}>
                {pathName === "tech" && (
                  <span className="title-text">{t('hourly_rate_precise_developer_force')}</span>
                )}
                {pathName === "private" && (<>
                  <span className="title-text" style={{fontSize: "50px", lineHeight: "60px"}}>{t('the_world_is_changing')}</span><br/>
                  <span className="title-text" style={{fontSize: "50px", lineHeight: "60px"}}>{t('what_about_your_business')}</span>
                </>)}
                {pathName === "agencies" && (
                  <span className="title-text">{t('want_us_to_make_your_clients_happy')}</span>
                )}
                {pathName === "startups" && (
                  <span className="title-text">{t('you_have_an_idea_you_yneed_a_realization')}</span>
                )}
                {pathName === "homepage" && (
                  <span className="title-text">{t('german_precision_for_exceptional_digital_products')}</span>
                )}
                
              </MDBRow>
              <MDBRow className="m-0 pt-4 py-3" style={{maxWidth: "600px"}}>
                
                {pathName === "tech" && (
                  <div>
                    <span className="description-text">{t('enhance_your_development_power')}</span><br/>
                    <span className="description-text">{t('enhance_your_development_power_2')}</span>
                  </div>
                  
                )}
                {pathName === "private" && (
                  <div>
                  <span className="description-text">{t('you_have_a_business')}</span><br/><br/>
                  <span className="description-text">{t('but_this_is_the_21st_century')}</span>
                </div>
                )}
                {pathName === "agencies" && (
                   <div>
                   <span className="description-text">{t('do_you_believe_in_businesses')}</span><br/>
                   <span className="description-text">{t('can_you_cimagine_a_life')}</span><br/>
                   <span className="description-text">{t('in_which_you_live_the')}</span>
                 </div>
                )}
                {pathName === "startups" && (
                  <span className="description-text">{t('you_had_an_idea_that_became')}</span>
                )}
                {pathName === "homepage" && (
                  <span className="description-text">{t('empowering_businesses')}</span>
                )}
                {pathName === "homepage" && (
                  <span className="description-text">{t('software_experten_aus_munchen')}</span>
                )}

              </MDBRow>
              <MDBRow className="m-0 py-4 d-flex justify-content-start align-items-center" style={{maxWidth: "600px"}}>
              <button onClick={() => {
                var element = document.getElementById('contact-us');
                var headerOffset = 59;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                })
              }}><span>{t('contact_us')}</span></button>
              </MDBRow>
              </div>
            </MDBCol>
            <MDBCol md="6" className="d-flex justify-content-center align-items-center interb2b-logo-col">

               
                   <img src={logo_big}  className="big-logo-img"/>
                   <img src={design_element_1} className="design-element-1"  />
                   <img src={cover_shadow}  height='78' className="cover-shadow-img"/>
                   <img src={orange_circle} className="orange-circle-img"/>
                 
            
            </MDBCol>
            
          </MDBRow>
         
        </div>
        </MDBContainer>
    </section>
  );
};

export default withTranslation()(FirstSection);
