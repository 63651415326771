// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_5 from "../../assets/media/images/Background Design Elements orange.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";

import javascript from "../../assets/media/images/javascript-logo-svgrepo-com.svg";
import springboot from "../../assets/media/images/springio-icon.svg";
import reactjs from "../../assets/media/images/React-icon.svg";
import java from "../../assets/media/images/java-svgrepo-com.svg";
import neo4j from "../../assets/media/images/neo4j.svg";
import lottiefiles from "../../assets/media/images/lottiefiles-logo.svg";
import invision from "../../assets/media/images/InVision_logo.svg";
import sketch from "../../assets/media/images/Sketch-logo-light.svg";
import figma from "../../assets/media/images/Figma_logo.svg";
import kotlin from "../../assets/media/images/Kotlin.png";
import unity from "../../assets/media/images/Unity.png";
import swift from "../../assets/media/images/Swift.png";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";


const TechStackSectionSpeciel = (props) => {

  
  // MULTILANGUAGE
  const { t } = i18n;


  return (
    <section className="third-section-spec" id="tech-stack">
    <MDBContainer style={{maxWidth: "1670px"}} className="third-container">
    <img src={design_element_5} className="deisgn-element-5"/>
    <div className="tech-title-text">
          <span>{t('what_we_offer_is_european_software')}</span>
    </div>
    <div className="middle-text-top pb-2">
      <span>
      {t('our_tech_stack_is')}
      </span>
    </div>
    <MDBRow className="m-0 py-4">
   
      <MDBCol md="7" center className="stack-col-right text-cnter p-0">
          <img src={design_element_3} className="design-element-3"/>
          <img src={design_element_4} className="design-element-4"/>
          <div className="technologie-1">
            <div className="javascript">
              <img src={javascript}/>
              <span>Javascript</span>
            </div>
            <div className="kotlin">
              <img src={kotlin}/>
            </div>
            <div className="unity">
              <img src={unity}/>
            </div>
          </div>
          <div className="technologie-4">
            <div className="lottiefiles">
              <img src={lottiefiles}/>
            </div>
            <div className="invision">
              <img src={invision}/>
            </div>
          </div>
          <div className="technologie-2">
            <div className="springboot">
              <img src={springboot}/>
              <span>Spring</span>
            </div>
            <div className="reactjs">
              <img src={reactjs}/>
              <span>React JS</span>
            </div>
          </div>
          <div className="technologie-3">
            <div className="java">
              <img src={java}/>
              <span>Java</span>
            </div>
            <div className="neo4j">
              <img src={neo4j}/>
              <span>Neo4J</span>
            </div>
          </div>
          <div className="technologie-5">
            <div className="sketch">
              <img src={sketch}/>
            </div>
            <div className="figma">
              <img src={figma}/>
            </div>
            <div className="swift">
              <img src={swift}/>
            </div>
          </div>
      </MDBCol>
      <MDBCol md="5" center className="ps-5">
        <div className="bottom-text-tech">
          <div style={{width: "372px"}}>
            <p>Backend</p>
            <span>•	Java</span><br/>
            <span>•	Spring Boot</span><br/>
            <p></p>
            <p className="d-flex justify-content-end">Frontend</p>
            <span className="d-flex justify-content-end">•	Javascript</span>
            <span className="d-flex justify-content-end">•	React</span>
            <span className="d-flex justify-content-end">•	MDBootstrap</span>
            <p></p>
            <p>Databases</p>
            <span>{"•	Neo4j (Graph)"}</span><br/>
            <span>•	Relational databases</span><br/>
            <p></p>
            <p className="d-flex justify-content-end">Mobile</p>
            <span className="d-flex justify-content-end">•	iOS / Swift</span>
            <span className="d-flex justify-content-end">•	Android / Kotlin</span>
            <p></p>
            <p>UX/UI design</p>
            <span>•	Figma</span><br/>
            <span>•	Invision</span><br/>
            <p></p>
            <p className="d-flex justify-content-center">AR/VR</p>
          </div>
             
        </div>
      </MDBCol>
    </MDBRow>
    </MDBContainer>
    <div className="tech-footer-text">
          <span>{t('we_all_are_EU_citizens')}</span>
    </div>
    <div className="tech-footer-contact-text">
          <span>{t('ask_for_free_consultation_now')}</span>
    </div>
    <div className="tech-footer-button">
    <button onClick={() => {
                var element = document.getElementById('contact-us');
                var headerOffset = 59;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                })
              }}><span>{t('contact_us')}</span></button>
    </div>
  </section>


  );
};

export default withTranslation()(TechStackSectionSpeciel);
