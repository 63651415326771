import React, { Component } from "react";
import Cookies from 'universal-cookie';
import { withTranslation, Trans } from 'react-i18next';




import{
	MDBContainer,
	MDBRow,
	MDBCol
} from 'mdb-react-ui-kit';

const cookies = new Cookies();


class UserAgreement extends Component {
	constructor(props) {
    super(props);
		
    this.state = {};

  };


  render() {

    return(
			
		<MDBContainer>
		<MDBRow className="m-0 py-4">
		<MDBCol md="12" size="12" className="m-0 px-4">
toDo
	</MDBCol>
	</MDBRow>
	</MDBContainer>
    );
  }
}
export default withTranslation()(UserAgreement);
