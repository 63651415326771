// react imports
import React, { useContext, useState, useEffect, useRef } from "react";
import AppContext from '../../AppContext';
import i18n from '../../i18n';

// MDBOOTSTRAP IMPORTS
import { MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink } from "mdb-react-ui-kit";

import logo from '../../assets/media/images/logo.svg'

// 3RD PARTY IMPORTS
import Cookies from 'universal-cookie';
const cookies = new Cookies();



function NavbarComponentMainPage(props) {
  const context = useContext(AppContext);

  // MULTILANGUAGE
  const { t } = i18n;

  
  const [language, setLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'EN' : 'DE');
  // EVENTS
  const getLanguage = (language) => {
    setLanguage(language)
    if(language === 'EN'){
      context.toEnglish();
      context.onRenderTrue();
    }else{
      context.toGerman();
      context.onRenderTrue();
    }
  };

  useEffect(() => {
    if(cookies.get('mylanguage')?.language === 'en'){
      setLanguage('EN')
    }else{
      setLanguage('DE')
    }
    context.onRender();
  }, [context.getState()?.isRender]);


  return (
          <div className=" main-navbar d-flex justify-content-center">
            <MDBNavbar expand='lg' light  >
              <MDBContainer style={{maxWidth: "1600px"}} id="mainpage">
                <MDBNavbarBrand>
                  <MDBNavbarLink className="d-none d-lg-block">
                    <img
                        src={logo}
                        height='60'
                        alt=''
                        loading='lazy'
                        
                    />
                  </MDBNavbarLink>
                  <div className="d-flex justify-content-end align-items-center">
                    <MDBNavbarItem link childTag='button' onClick={() => getLanguage('EN')}>
                      <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'en'} href='#'  className="en-item">en</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem link childTag='button' onClick={() => getLanguage('DE')} className="de-item">
                      <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'de'} href='#' className="de-item">de</MDBNavbarLink>
                    </MDBNavbarItem>
                  </div>
                </MDBNavbarBrand>
              </MDBContainer>
            </MDBNavbar>
          </div>
         );
}

export default NavbarComponentMainPage;
