// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from 'framer-motion'


// CUSTOM COMPONENTS
import HomePageTechCompaniesResponsiv from './respo/HomePageTechCompaniesResponsiv'
import HomePageTechCompaniesDesktop from './desktop/HomePageTechCompaniesDesktop'


const HomePageTechCompanies = (props) => {


  // STATES
  const [maxDevice, setMaxDevice] = useState(true);
  useEffect(() => {
      
    setMaxDevice(window.matchMedia('(max-device-width: 992px)').matches)
    
 }, [window.matchMedia('(max-device-width: 992px)').matches]);
 

  return (
    <>
        <motion.div intial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0, transition: { duration: 0.6 }}}
                    className="d-none d-lg-block"
        >
					<HomePageTechCompaniesDesktop/>
        </motion.div>
				<div className="d-lg-none d-block">
					<HomePageTechCompaniesResponsiv/>
				</div>
    </>


  );
};

export default HomePageTechCompanies;
