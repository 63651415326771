// react imports
import React, { useContext, useState, useEffect } from "react";
import { Outlet, Link, NavLink, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import styles from '../../assets/scss/modules/layout.module.scss';
import AppContext from '../../AppContext';
import i18n from '../../i18n';
import Flags from 'country-flag-icons/react/3x2'

// MDBOOTSTRAP IMPORTS
import { MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse, } from "mdb-react-ui-kit";

// IMPORT LAYOUT COMPONENTS
//import Footer from "./footer/Footer";
//import Header from "./header/Header";
import HeaderLanguageSelector from "./language-selector/HeaderLanguageSelector";

import logo from '../../assets/media/images/logo.svg'
import back_arrow from '../../assets/media/images/back-arrow.png'
import hamburger from '../../assets/media/images/Responsiv/menu.svg'
import close from '../../assets/media/images/Responsiv/close.svg'
import youtube from '../../assets/media/images/yt_logo_mono_dark.png'

// 3RD PARTY IMPORTS
import Moment from 'moment';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



function NavbarComponentTeamPage(props) {
  const context = useContext(AppContext);
  const { search } = useLocation();

  
  
  // STATES
  const [showBasic, setShowBasic] = useState(false);
  const [activeItem, setActiveItem] = useState(context.getState()?.activeItem);
  
  // react router navigation
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // MULTILANGUAGE
  const { t } = i18n;

  
  const [language, setLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'EN' : 'DE');
  // EVENTS
  const getLanguage = (language) => {
    setLanguage(language)
    if(language === 'EN'){
      context.toEnglish();
      context.onRenderTrue();
    }else{
      context.toGerman();
      context.onRenderTrue();
    }
  };

  useEffect(() => {
    if(cookies.get('mylanguage')?.language === 'en'){
      setLanguage('EN')
    }else{
      setLanguage('DE')
    }
    context.onRender();
  }, [context.getState()?.isRender]);


  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (<>
          <div className=" fixed-main-navbarge d-flex justify-content-center">
            <MDBNavbar  light  className={context.getState()?.isScroll ? "scroll" : ""}>
            <MDBContainer style={{maxWidth: "1920px"}} id="teampage">
                <MDBNavbarBrand onClick={() => {
                              if(context.getState()?.pathname !== undefined){
                                navigate(context.getState()?.pathname === "homepage" ? '/homepage' : '/homepage/' + context.getState()?.pathname);
                              }else{
                                navigate(-1)
                              }
                              
                              context.onSetIsScroll(false); 
                              context.onSetActiveItem("1");}} >
                  <MDBNavbarLink active={context.getState()?.activeItem === "1"}>
                    <img
                        src={back_arrow}
                        height='14'
                        alt=''
                        loading='lazy'
                    />
                    <img
                        src={logo}
                        height='60'
                        alt=''
                        loading='lazy'
                    />
                  </MDBNavbarLink>
                </MDBNavbarBrand>

                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="d-flex justify-content-end align-items-center">
                    <MDBNavbarItem link childTag='button' onClick={() => getLanguage('EN')}>
                      <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'en'} href='#'  className="en-item">en</MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem link childTag='button' onClick={() => getLanguage('DE')} className="de-item">
                      <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'de'} href='#' className="de-item">de</MDBNavbarLink>
                    </MDBNavbarItem>
                  </div>
                 
                </div>
                </MDBNavbarNav>
            </MDBContainer>
            </MDBNavbar>
          </div>
         
         </>);
}

export default NavbarComponentTeamPage;
