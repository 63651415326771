// react imports
import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import EmailForm from "../common/EmailForm"
import FooterPage from "../layout/footer/FooterPage"

import footer_background from "../../assets/media/images/Footer Background.png";
import design_element_14 from "../../assets/media/images/Group 14.png";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";

   
const FooterSection = (props) => {

  // STATES
  const [imageHeight_2, setImageHeight_2] = useState(document.getElementById("backgroundImage_2")?.height);
  
  // MULTILANGUAGE
  const { t } = i18n;


  useEffect(() => {
      setTimeout(() => {
        setImageHeight_2(document.getElementById("backgroundImage_2")?.height)
      },500)
   }, []);
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  const handleWindowSizeChange = () => {
    setImageHeight_2(document.getElementById("backgroundImage_2")?.height);
  };



  return (
     <section className="eighth-section" id="contact-us" >
      <img src={footer_background} className="footer-background" id="backgroundImage_2" style={{marginBottom: imageHeight_2  !== undefined  && imageHeight_2 > 1100 ? 1100 - imageHeight_2 : 0}}/>
      {imageHeight_2 !== undefined && (
        <MDBContainer style={{maxWidth: "1670px"}}>
          <span className="description-text-telefon">Tel.: +49 172 3236227</span>
        <MDBRow className="m-0 px-5 d-flex justify-content-end align-items-center">
          <MDBCol md="6" center className="middle-col-left">
          <MDBRow className="m-0 py-2" style={{maxWidth: "600px"}}>
              <span className="description-text">{t('send_us_a_message')}</span>
            </MDBRow>
            <MDBRow className="m-0 py-2" style={{maxWidth: "600px"}}>
              <span className="title-text">{t('your_inquiries_matter_and')}</span>
            </MDBRow>
            <MDBRow className="m-0 py-4" style={{maxWidth: "600px"}}>
              <EmailForm/>
            </MDBRow>
          </MDBCol>
          <MDBCol md="6" className="d-flex justify-content-end align-items-center interb2b-logo-col">
            <img src={design_element_14}  className="design-element-14"/>
          </MDBCol>
          <div className="footer-navbar">
            <FooterPage/>
          </div>
          
        </MDBRow>
        </MDBContainer>
      )}
    </section>
  );
};

export default withTranslation()(FooterSection);
