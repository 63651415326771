// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import AppContext from '../../AppContext';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';
import NavbarComponent from "../navbarcomponent/NavbarComponent"

import logo from "../../assets/media/images/Responsiv/logo.svg";
import design_element_1 from "../../assets/media/images/Responsiv/Background Design element - Circles.svg";
import blue_background from "../../assets/media/images/Responsiv/bgHeader.png";


import { MDBRow, MDBCol, MDBCard, MDBCarousel,
  MDBCarouselItem
   } from "mdb-react-ui-kit";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



// CUSTOM COMPONENTS



const FirstSectionRespo = (props) => {

  //MDBAlert
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // REACT ROUTER PARAMS - for processing url
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  

  // STATES
  const [pathName, setPathName] = useState(window.location.pathname.split("/")[window.location.pathname.split("/").length-1])
  const [imageHeight, setImageHeight] = useState(document.getElementById("backgroundImage-respo")?.height);
  
  // MULTILANGUAGE
  const { t } = i18n;

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  const handleWindowSizeChange = () => {
    setImageHeight(document.getElementById("backgroundImage")?.height);
  };
  useEffect(() => {
    setPathName(window.location.pathname.split("/")[window.location.pathname.split("/").length-1])
    
  }, [window.location.pathname.split("/")[window.location.pathname.split("/").length-1]]);

  
  return (
    <>
    <section className="first-section pb-4" >
        <img src={blue_background} className="blue-background" id="backgroundImage-respo"/>
        
        <div className="main-navbar" id="main-navbar-id">
          <NavbarComponent/>
        </div>
        <div className="px-3">
          
            

          <MDBRow center className="m-0 d-flex justify-content-end align-items-center" style={{paddingTop: "130px"}}>
            <MDBCol md="12" center className="middle-col-left px-0" >
              <MDBRow className="m-0 py-2" style={{maxWidth: "350px"}}>
                {pathName === "tech" && (
                  <span className="title-text-first">{t('hourly_rate_precise_developer_force')}</span>
                )}
                {pathName === "private" && (<>
                  <span className="title-text" style={{fontSize: "50px", lineHeight: "60px"}}>{t('the_world_is_changing')}</span><br/>
                  <span className="title-text" style={{fontSize: "50px", lineHeight: "60px"}}>{t('what_about_your_business')}</span>
                </>)}
                {pathName === "agencies" && (
                  <span className="title-text">{t('want_us_to_make_your_clients_happy')}</span>
                )}
                {pathName === "startups" && (
                  <span className="title-text">{t('you_have_an_idea_you_yneed_a_realization')}</span>
                )}
                {pathName === "homepage" && (
                  <span className="title-text">{t('german_precision_for_exceptional_digital_products')}</span>
                )}
              </MDBRow>
              <MDBRow className="m-0 pt-4 py-3" style={{maxWidth: "600px"}}>
                
                {pathName === "tech" && (
                  <div>
                    <span className="description-text">{t('enhance_your_development_power')}</span><br/>
                    <span className="description-text">{t('enhance_your_development_power_2')}</span>
                  </div>
                  
                )}
                {pathName === "private" && (
                  <div>
                  <span className="description-text">{t('you_have_a_business')}</span><br/><br/>
                  <span className="description-text">{t('but_this_is_the_21st_century')}</span>
                </div>
                )}
                {pathName === "agencies" && (
                   <div>
                   <span className="description-text">{t('do_you_believe_in_businesses')}</span><br/>
                   <span className="description-text">{t('can_you_cimagine_a_life')}</span><br/>
                   <span className="description-text">{t('in_which_you_live_the')}</span>
                 </div>
                )}
                {pathName === "startups" && (
                  <span className="description-text">{t('you_had_an_idea_that_became')}</span>
                )}
                {pathName === "homepage" && (
                  <span className="description-text">{t('empowering_businesses')}</span>
                )}
                {pathName === "homepage" && (
                  <span className="description-text">{t('software_experten_aus_munchen')}</span>
                )}

              </MDBRow>
              <MDBRow className="m-0 pt-2 pb-5 d-flex justify-content-center align-items center" style={{maxWidth: "600px"}}>
              <button onClick={() => {
                var element = document.getElementById('contact-us-respo');
                var headerOffset = 59;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                })
              }}><span>Contact Us</span></button>
              </MDBRow>
            </MDBCol>
           
          </MDBRow>
         
        </div>
    </section>
    </>


  );
};

export default withTranslation()(FirstSectionRespo);
