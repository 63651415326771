// react imports
import React, { useContext, useState, useEffect } from "react";
import AppContext from '../../../AppContext';
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n';

import { MDBContainer
   } from "mdb-react-ui-kit";

import AboutUsSection from "../../sections/AboutUsSection";
import ReferencesSection from "../../sections/ReferencesSection";
import FooterSection from "../../sections/FooterSection";
import TechStackSection from "../../sections/TechStackSection";
import YourVisionSectionSpeciel from "../../sections/YourVisionSectionSpeciel";
import FirstSection from "../../sections/FirstSection";
import TechnologieSection from "../../sections/TechnologieSection";


const HomePageStartupsDesktop = (props) => {


  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);
  
  // MULTILANGUAGE
  const { t } = i18n;

  
  useEffect(() => {
    document.addEventListener("scroll", scrollEventListener);
    return () => {
        document.removeEventListener("scroll", scrollEventListener);
    };
  }, []);

  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  const scrollEventListener = () => {
    var item = "1";
    context.onSetIsScroll(window.scrollY > 40 );
    
    if(context.getState()?.isNavChange === false){
      if(window.scrollY <  document.getElementById(pathname === "tech" ? 'tech-stack' : 'your-vision').getBoundingClientRect().top + window.pageYOffset -200){
        context.onSetActiveItem("1");
      }
      
      if(window.scrollY > document.getElementById(pathname === "tech" ? 'tech-stack' : 'your-vision').getBoundingClientRect().top + window.pageYOffset -250){
        context.onSetActiveItem("5");
      }
      
      if(window.scrollY > document.getElementById(pathname === "tech" ? 'your-vision' : 'tech-stack').getBoundingClientRect().top + window.pageYOffset -180){
        context.onSetActiveItem("2");
      }
      
      if(window.scrollY > document.getElementById('technologie-part').getBoundingClientRect().top + window.pageYOffset -200){
        context.onSetActiveItem("4");
      }
      
      if(window.scrollY > document.getElementById('about-us').getBoundingClientRect().top + window.pageYOffset -200){
        context.onSetActiveItem("6");
      }
      
      if(window.scrollY > document.getElementById('references').getBoundingClientRect().top + window.pageYOffset -200){
        context.onSetActiveItem("7");
      }
    }          
  }

  return (
          <div  style={{backgroundColor: "#F6F8F9"}}>
            <FirstSection />

            {/*your-vision*/}
            <YourVisionSectionSpeciel/>

            {/*tech-stack*/}
            <TechStackSection/>

            {/*technologie-part*/}
            <TechnologieSection/>

            <MDBContainer style={{maxWidth: "1670px"}}>

            {/*about-us*/}
            <AboutUsSection pathName={pathname}/>

            {/*references*/}
            <ReferencesSection/>

            </MDBContainer>

            <FooterSection/>

          </div>
  );
};

export default withTranslation()(HomePageStartupsDesktop);
