// react imports
import React, { useContext, Component, useRef  } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import Moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';
import emailjs from '@emailjs/browser';
import { EmailData } from '../../data/emailkey';

import AppContext from '../../AppContext';

import { MDBAlert, 
         MDBRow, 
         MDBIcon,
         MDBCol,
         MDBContainer,
         MDBTextArea
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class EmailForm extends Component {


  constructor(props) {
    super(props);
      this.state = {from_email: "",
                    message: ""
      };
    };

  


  onCustomer = (e) => {
    let self = this;
    e.preventDefault();
    emailjs.sendForm(EmailData.serviceId, EmailData.templateId, this.props.form.current, EmailData.privateId)
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        self.setState({from_email: "", message: ""});
      }, function(err) {
        console.log('FAILED...', err);
      }
    );
    
  }

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

    
render() {
  return (
    <form ref={this.props.form} onSubmit={(e) => this.onCustomer(e)} autoComplete="off">
    <MDBRow className="pb-3 m-0" style={{maxWidth: "600px"}}>
        <label htmlFor="from_email">{this.props.t('your_email')}</label><br/>
        <input
        id='from_email'
        name='from_email'
        type='text'
        onChange={this.changeHandler}
        value={this.state.from_email}
        required
        />
    </MDBRow>

    <MDBRow className="pb-4 m-0" style={{maxWidth: "600px"}}>
        <label htmlFor="message">{this.props.t('message')}</label><br/>
        <textarea 
        id='message'
        name='message'
        type='message'
        onChange={this.changeHandler}
        value={this.state.message}
        rows={window.matchMedia('(max-device-width: 992px)').matches ? "2" : "4"} 
        />
    </MDBRow>
    <MDBRow className={window.matchMedia('(max-device-width: 992px)').matches ? "m-0 py-3 d-flex justify-content-center align-items center" : "m-0 py-3 d-flex justify-content-end align-items center"} style={{maxWidth: "600px"}}>
        <button  type="submit"><span>{this.props.t('send_message')}</span></button>
    </MDBRow>
    </form>


  );
}
 
};

function EmailFormFunc(props) {
  let [searchParams] = useSearchParams();
  const { t } = i18n;
  const navigate = useNavigate();
  const form = useRef();
  const context = useContext(AppContext);
  return <EmailForm searchParams={searchParams} navigate={navigate}  context={context} t={t} form={form}/>;
}

export default withTranslation()(EmailFormFunc);