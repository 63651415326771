// react imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';

import interb2b from "../../assets/media/images/interb2b.png";
import studicore from "../../assets/media/images/studicore.png";
import appentum from "../../assets/media/images/appentum.png";
import itboda from "../../assets/media/images/IT-boda.png";
import arrow from "../../assets/media/images/arrow.png";

import { MDBRow, MDBCol
   } from "mdb-react-ui-kit";


const AboutUsSectionRespo = (props) => {


  const navigate = useNavigate();
  

  const { t } = i18n;

 
  return (
    <>
 
    <section className="ninth-section" id="about-us-respo">
      <MDBRow className="m-0 px-3 py-4">
        <MDBCol  md="12" center className="about-col-right text-cnter">
            
            <div className="top-text py-3">
              <span>
              {t('about_our')}
              </span>
            </div>
            <div className="middle-text pb-2">
              <span>
              {t('professionals')}
              </span>
            </div>
            <div className="bottom-text">
              <span>
              {t('with_various_engineering_knowledge')}
              </span>
            </div>
            <div className="partner-1">
              <div className="interb2b" onClick={() => {navigate('/teampage'); setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  },500)}}>
                <img src={interb2b} width='160' style={{marginRight: "0px", marginLeft: "-10px"}}/>
                <img src={arrow} height='12' width='18'/>
              </div>
              <div className="studicore">
                <a href="https://www.studicore.hu/" target="_blank">
                  <img src={studicore} width='227' style={{marginRight: "0px", marginLeft: "-10px"}}/>
                  <img src={arrow} height='12' width='18'/>
                </a>
              </div>
              <div className="appentum">
                <a href="https://appentum.de" target="_blank">
                  <img src={appentum} width='180' style={{marginRight: "15px", marginLeft: "-10px"}}/>
                  <img src={arrow} height='12' width='18'/>
                </a>
              </div>
              <div className="itboda">
                <img src={itboda}/>
              </div>
            </div>
            
            
        </MDBCol>
      </MDBRow>
    </section>

    </>
  );
};

export default withTranslation()(AboutUsSectionRespo);
