// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_2 from "../../assets/media/images/Background Design Elements.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";
import design_element_9 from "../../assets/media/images/landingPagePreview.svg";
import design_element_10 from "../../assets/media/images/Mobile Image.svg";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";


const YourVisionSectionRespo = (props) => {
  
  // MULTILANGUAGE
  const { t } = i18n;

 
  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (
    <section className="second-section-spec" id="your-vision-respo">
        <MDBRow className="m-0 px-3 pb-4" >
            <MDBCol md="12" center className="p-0">
            <div className="top-text pb-2">
                <span>
                {t('enhance_your_business')}
                </span>
                
            </div>
            <div className="middle-text pb-2">
                <span>
                {t('your_vision_our_code')}
                </span>
                
            </div>
            <div className="bottom-text">
                <span>
                {t('in_today_text')}
                </span>
            </div>
            </MDBCol>
            <MDBCol md="12" className="d-flex justify-content-start align-items-center design-col px-0">
            <img src={design_element_2} className="deisgn-element-2"/>
            <div className="first-card">
                <img src={design_element_3} className="design-element-3"/>
                <img src={design_element_9} className="design-element-9"/>
                <img src={design_element_10} className="design-element-10"/>
            </div>
            </MDBCol>
            
        </MDBRow>
    </section>
  );
};

export default withTranslation()(YourVisionSectionRespo);
