// react imports
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import AppContext from '../../AppContext';
import i18n from '../../i18n';

import design_element_5 from "../../assets/media/images/Background Design Elements orange.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";
import design_element_15 from "../../assets/media/images/professionals_illustration.png";


import interb2b from "../../assets/media/images/interb2b.png";
import studicore from "../../assets/media/images/studicore.png";
import appentum from "../../assets/media/images/appentum.png";
import itboda from "../../assets/media/images/IT-boda.png";
import arrow from "../../assets/media/images/arrow.png";


import { MDBRow, MDBCol
   } from "mdb-react-ui-kit";


const AboutUsSection = (props) => {

  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);
  const navigate = useNavigate();
  
  // MULTILANGUAGE
  const { t } = i18n;
 
  return (
    <section className="ninth-section" id="about-us">
      <img src={design_element_5} className="deisgn-element-5"/>
      <MDBRow className="m-0 px-5 py-4">
        <MDBCol  xxl="6" xl="6" lg="6" md="6" center className="about-col-left ">
          <img src={design_element_3} className="design-element-3"/>
          <img src={design_element_15} className="design-element-15"/>
        </MDBCol>
        <MDBCol  xxl="6" xl="6" lg="6" md="6" center className="about-col-right text-cnter">
            
            <img src={design_element_4} height='86' className="design-element-4"/>
            <div className="top-text py-3">
              <span>
              {t('about_our')}
              </span>
            </div>
            <div className="middle-text pb-2">
              <span>
              {t('professionals')}
              </span>
            </div>
            <div className="bottom-text">
              <span>
              {t('with_various_engineering_knowledge')}
              </span>
            </div>
            <div className="partner-1">
              <div className="interb2b" onClick={() => {navigate('/teampage'); context.onSetPathName(props.pathName); setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        },500)}}>
                <img src={interb2b} width='160' style={{marginRight: "0px", marginLeft: "-10px"}}/>
                <img src={arrow} height='12' width='18'/>
              </div>
              <div className="studicore">
                <a href="https://www.studicore.hu/about/" target="_blank">
                  <img src={studicore} width='227' style={{marginRight: "0px", marginLeft: "-10px"}}/>
                  <img src={arrow} height='12' width='18'/>
                </a>
              </div>
            </div>
            <div className="partner-1">
              <div className="appentum">
                <a href="https://appentum.de" target="_blank" className="d-flex align-items-center justify-content-start">
                  <img src={appentum} width='180' style={{marginRight: "15px", marginLeft: "-10px"}}/>
                  <img src={arrow} height='12' width='18'/>
                </a>
              </div>
              <div className="itboda">
                <img src={itboda}/>
              </div>
            </div>
            <div className="partner-3">
              
            </div>
        </MDBCol>
      </MDBRow>
    </section>


  );
};

export default withTranslation()(AboutUsSection);
