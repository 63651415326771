// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import AppContext from '../../AppContext';
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../i18n';
import { motion } from 'framer-motion'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

import NavbarComponentMainPage from "../navbarcomponent/NavbarComponentMainPage"

import animation_1s from "../../assets/media/videos/1_new.json";
import animation_1e from "../../assets/media/videos/1_end_new.json";

import animation_2s from "../../assets/media/videos/2_new.json";
import animation_2e from "../../assets/media/videos/2_end_new.json";

import animation_3s from "../../assets/media/videos/3_new.json";
import animation_3e from "../../assets/media/videos/3_end_new.json";

import animation_4s from "../../assets/media/videos/4_new.json";
import animation_4e from "../../assets/media/videos/4_end_new.json";

import animation_1s_o from "../../assets/media/videos/1.json";

import animation_2s_o from "../../assets/media/videos/2.json";

import animation_3s_o from "../../assets/media/videos/3.json";

import animation_4s_o from "../../assets/media/videos/4.json";

import animation_5s from "../../assets/media/videos/5.json";
import animation_5e from "../../assets/media/videos/5_end.json";
import animation_swipe from "../../assets/media/videos/swipe.json";

import anim_arrow from "../../assets/media/images/CTA - Button.png";
import anim_arrow_hover from "../../assets/media/images/CTA - Button hover.png";
import skip_arrow from "../../assets/media/images/skip-arrow.png";


import overlayEdge1  from "../../assets/media/images/line.png";
import overlayEdge2  from "../../assets/media/images/overlayEdge2.png";
import overlayEdge3  from "../../assets/media/images/overlayEdge3.png";
import overlayEdge4  from "../../assets/media/images/overlayEdge4.png";

import { MDBRow, MDBCol, MDBCard, MDBContainer
   } from "mdb-react-ui-kit";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



// CUSTOM COMPONENTS

{/*autoplay
                    loop*/}

const MainPage = (props) => {

  //MDBAlert
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // REACT ROUTER PARAMS - for processing url
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  
  const playerRef_1s = useRef();
  const playerRef_1sRespo = useRef();
  const playerRef_2s = useRef();
  const playerRef_2sRespo = useRef();
  const playerRef_3s = useRef();
  const playerRef_3sRespo = useRef();
  const playerRef_4s = useRef();
  const playerRef_4sRespo = useRef();
  const playerRef_5s = useRef();
  const playerRef_1e = useRef();
  const playerRef_2e = useRef();
  const playerRef_3e = useRef();
  const playerRef_4e = useRef();
  const playerRef_5e = useRef();
  const playerRefSwipe_1 = useRef();
  const playerRefSwipe_2 = useRef();
  const playerRefSwipe_3 = useRef();
  const playerRefSwipe_4 = useRef();

  // STATES
  const [isCard, setIsCard] = useState(true);
  const [activeSlideItem, setActiveSlideItem] = useState("0");
  const [isFirstReady, setIsFirstReady] = useState(false);
  const [isSecondReady, setIsSecondReady] = useState(false);
  const [isThirdReady, setIsThirdReady] = useState(false);
  const [isFourthReady, setIsFourthReady] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isFirstHover, setIsFirstHover] = useState(false);
  const [isSecondHover, setIsSecondHover] = useState(false);
  const [isThirdHover, setIsThirdHover] = useState(false);
  const [isFourthHover, setIsFourthHover] = useState(false);
  const [isFifthReady, setIsFifthReady] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null)
  const [pathName, setPathName] = useState(window.location.pathname.split("/")[window.location.pathname.split("/").length-1])
  
  // MULTILANGUAGE
  const { t } = i18n;




  useEffect(() => {
    setPathName(window.location.pathname.split("/")[window.location.pathname.split("/").length-1]);
  }, [context.getState()?.isRender]);

  useEffect(() => {
    setTimeout(() => {
      playerRef_5s.current.setPlayerSpeed(.7)
      playerRef_5s.current.play()
    },500)
    
  }, []);


  
  const onPlayTwo = () => {
    if(!isFifthReady){
      setIsFifthReady(true);
    }
    setTimeout(() => {
      playerRef_5e.current.play()
    },300)
   
  }

  const onPlayOne = () => {
    if(isFifthReady){
      playerRef_5e.current.setPlayerSpeed(.7)
      playerRef_5e.current.stop()
      setIsFifthReady(false);
    }
    setTimeout(() => {
      playerRef_5s.current.play()
    },300)
    
  }

  const handleFirstMouseEnter = event => {
    onPlayTwo()
    
    
    setDelayHandler(setTimeout(() => {
      playerRef_1s.current.setPlayerSpeed(.7)
      playerRef_1s.current.play();
      setIsFirstHover(true); 
      setIsHover(true);
      setIsSecondReady(false); 
      setIsThirdReady(false); 
      setIsFourthReady(false); 
      
    }, 600))
  }

  const handleFirstMouseLeave = () => {
      clearTimeout(delayHandler)
      if(isFirstReady){
        playerRef_1e.current.play()
      }else{
        playerRef_1s.current.stop();
        setTimeout(() => {
          onPlayOne();
        },500)
        
      }
      setIsFirstHover(false);
      setIsHover(false);
  }

  const handleSecondMouseEnter = event => {
    onPlayTwo()
    setDelayHandler(setTimeout(() => {
      playerRef_2s.current.setPlayerSpeed(.7)
      playerRef_2s.current.play();
      setIsHover(true);
      setIsSecondHover(true); 
      setIsFirstReady(false); 
      setIsThirdReady(false); 
      setIsFourthReady(false); 
      
    }, 800))
  }

  const handleSecondMouseLeave = () => {
      clearTimeout(delayHandler)
      if(isSecondReady){
        playerRef_2e.current.play()
      }else{
        playerRef_2s.current.stop();
        setTimeout(() => {
          onPlayOne();
        },500)
      }
      setIsSecondHover(false);
      setIsHover(false);
  }

  const handleThirdMouseEnter = event => {
    onPlayTwo()
    setDelayHandler(setTimeout(() => {
      playerRef_3s.current.setPlayerSpeed(.7)
      playerRef_3s.current.play();
      setIsHover(true);
      setIsThirdHover(true); 
      setIsSecondReady(false); 
      setIsFirstReady(false); 
      setIsFourthReady(false); 
      
    }, 800))
  }

  const handleThirdMouseLeave = () => {
      clearTimeout(delayHandler)
      if(isThirdReady){
        playerRef_3e.current.play()
      }else{
        playerRef_3s.current.stop();
        setTimeout(() => {
          onPlayOne();
        },500)
      }
      setIsThirdHover(false);
      setIsHover(false);
  }

  const handleFourthMouseEnter = event => {
    onPlayTwo()
    setDelayHandler(setTimeout(() => {
      playerRef_4s.current.setPlayerSpeed(.7)
      playerRef_4s.current.play();
      setIsHover(true);
      setIsFourthHover(true); 
      setIsSecondReady(false); 
      setIsThirdReady(false); 
      setIsFirstReady(false); 
      
    }, 800))
  }

  const handleFourthMouseLeave = () => {
      clearTimeout(delayHandler)
      if(isFourthReady){
        playerRef_4e.current.play()
      }else{
        playerRef_4s.current.stop();
        setTimeout(() => {
          onPlayOne();
        },500)
      }
      setIsFourthHover(false);
      setIsHover(false);
  }



  const handleSwipeLoad = event => {
    setDelayHandler(setTimeout(() => {
      if(event === 0) {

      }
      playerRef_2s.current.setPlayerSpeed(.7)
      playerRef_2s.current.play();
      setIsHover(true);
      setIsSecondHover(true); 
      setIsFirstReady(false); 
      setIsThirdReady(false); 
      setIsFourthReady(false); 
      //onPlayTwo()
    }, 500))
  }
  
  const onSwipePlay_1 = () => {
    setTimeout(() => {
      playerRefSwipe_1.current.stop()
      playerRefSwipe_1.current.play()
    },7000);
  }
  const onSwipePlay_2 = () => {
    setTimeout(() => {
      playerRefSwipe_2.current.stop()
      playerRefSwipe_2.current.play()
    },7000);
  }
  const onSwipePlay_3 = () => {
    setTimeout(() => {
      playerRefSwipe_3.current.stop()
      playerRefSwipe_3.current.play()
    },7000);
  }
  const onSwipePlay_4 = () => {
    setTimeout(() => {
      playerRefSwipe_4.current.stop()
      playerRefSwipe_4.current.play()
    },7000);
  }


  return (
    <motion.div intial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0, transition: { duration: 0.8 }}}
    >
      <div className="mainpage-section">
        <div className="main-navbar" id="main-navbar-id">
          <NavbarComponentMainPage isMainpage={true}/>
        </div>
        <MDBContainer className="lottie-container">
          <div className="start-lottie">
          {!isFifthReady ?
            <Player 
              id="lottie-start"
              onEvent={event => {
                if (event === 'complete') {setIsFifthReady(true);
                  if (window.matchMedia('(max-device-width: 992px)').matches){
                    setTimeout(() => {
                      playerRef_5e.current.setPlayerSpeed(.7)
                      playerRef_5e.current.play();
                    },300)
                  }}
              }}
              keepLastFrame
              src={animation_5s}
              style={{ height: 'auto', width: '100%' }}
              ref={playerRef_5s}
            ></Player>:
            <Player 
              id="lottie-start"
              onEvent={event => {
                if (event === 'complete') {setIsFifthReady(false); 
                if (window.matchMedia('(max-device-width: 992px)').matches){
                  setTimeout(() => {
                    if(activeSlideItem === "0" ){
                      playerRef_1sRespo.current.setPlayerSpeed(.7)
                      playerRef_1sRespo.current.play();
                    }
                    if(activeSlideItem  === "1" ){
                      playerRef_2sRespo.current.setPlayerSpeed(.7)
                      playerRef_2sRespo.current.play();
                    }
                    if(activeSlideItem  === "2" ){
                      playerRef_3sRespo.current.setPlayerSpeed(.7)
                      playerRef_3sRespo.current.play();
                    }
                    if(activeSlideItem  === "3" ){
                      playerRef_4sRespo.current.setPlayerSpeed(.7)
                      playerRef_4sRespo.current.play();
                    }
                  },300)
                }}
              }}
              keepLastFrame
              src={animation_5e}
              style={{ height: 'auto', width: '100%' }}
              ref={playerRef_5e}
            ></Player>}
          </div>
        </MDBContainer>
        <div className="main-page-center px-2">
           
            <div className={isHover ? "title-part hover  px-0" : "title-part  px-0"}>
                <span>{t('meet_tiger_1')}<span style={{fontWeight: 600, opacity: 0.9}}> {t('meet_tiger_2')}</span> {t('meet_tiger_3')} {t('meet_tiger_4')}</span>
            </div>
            
            <div className=" d-none d-lg-block">
              <MDBContainer className="partners-part">

                
              <div className={isFirstHover ? "layout-div hover" : "layout-div"}>
                <div className={isFirstHover ? "first-anim hover" : "first-anim"}>
                  <div className="d-flex">
                    {!isFirstReady ?
                      <Player
                        onEvent={event => {
                          if (event === 'complete') setIsFirstReady(true);
                        }}
                        keepLastFrame
                        src={animation_1s}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_1s}
                      >

                      </Player>:

                      <Player
                        onEvent={event => {
                          if (event === 'complete') {
                            setIsFirstReady(false); 
                            setTimeout(() => {
                              onPlayOne()
                            },400)
                          }
                        }}
                        keepLastFrame
                        src={animation_1e}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_1e}
                      >

                      </Player>
                    }
                    <div className="video-text">
                      <div className={isFirstHover ? "anim-text hover" : "anim-text"}>
                          <span>{t('be_a_helping_paw_for')}</span><br/>
                          <span>{t('be_a_helping_paw_for_2')}</span>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="partner-button first" onMouseEnter={() => { handleFirstMouseEnter() }}
                                                onMouseLeave={() => { handleFirstMouseLeave() }} 
                                                onClick={() => { navigate('/homepage/tech'); context.onRenderTrue();}}>
                    <span className="description-text">{t('tech_comapnies')}</span>
                    <button className="anim-button"  >
                      <img src={isFirstHover ? anim_arrow_hover : anim_arrow} alt=""/>
                    </button>
                </div>
              </div>

              <div className={isSecondHover ? "layout-div hover" : "layout-div"}>
                <div className={isSecondHover ? "first-anim hover" : "first-anim"}>
                  <div className="d-flex">
                    {!isSecondReady ?
                      <Player
                        onEvent={event => {
                          if (event === 'complete') setIsSecondReady(true);
                        }}
                        keepLastFrame
                        src={animation_2s}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_2s}
                      >

                      </Player>:

                      <Player
                        onEvent={event => {
                          if (event === 'complete') {
                            setIsSecondReady(false);
                            setTimeout(() => {
                              onPlayOne()
                            },400)
                          }
                        }}
                        keepLastFrame
                        src={animation_2e}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_2e}
                      >

                      </Player>
                    }
                    <div className="video-text">
                      <div className={isSecondHover ? "anim-text hover" : "anim-text"}>
                          <span>{t('develop_an_app_based')}</span><br/>
                          <span>{t('develop_an_app_based_2')}</span>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="partner-button second" onMouseEnter={() => { handleSecondMouseEnter() }}
                                                onMouseLeave={() => { handleSecondMouseLeave() }} 
                                                onClick={() => { navigate('/homepage/agencies'); context.onRenderTrue();}}>
                    <span className="description-text">{t('agencies')}</span>
                    <button className="anim-button"  >
                      <img src={isFirstHover ? anim_arrow_hover : anim_arrow} alt=""/>
                    </button>
                </div>
              </div>

              <div className={isThirdHover ? "layout-div hover" : "layout-div"}>
                <div className={isThirdHover ? "first-anim hover" : "first-anim"}>
                  <div className="d-flex">
                  <div className="video-text">
                      <div className={isThirdHover ? "anim-text right hover" : "anim-text right"}>
                          <span>{t('gain_my_business_with')}</span><br/>
                          <span>{t('gain_my_business_with_2')}</span>
                      </div>
                    </div>  
                    {!isThirdReady ?
                      <Player
                        onEvent={event => {
                          if (event === 'complete') setIsThirdReady(true);
                        }}
                        keepLastFrame
                        src={animation_3s}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_3s}
                      >

                      </Player>:

                      <Player
                        onEvent={event => {
                          if (event === 'complete') {
                            setIsThirdReady(false);
                            setTimeout(() => {
                              onPlayOne()
                            },400)
                          }
                        }}
                        keepLastFrame
                        src={animation_3e}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_3e}
                      >

                      </Player>
                    }
                  </div>
                </div>
                <div className="partner-button third" onMouseEnter={() => { handleThirdMouseEnter() }}
                                                onMouseLeave={() => { handleThirdMouseLeave() }} 
                                                onClick={() => { navigate('/homepage/private'); context.onRenderTrue();}}>
                    <span className="description-text">{t('dreamers')}</span>
                    <button className="anim-button"  >
                      <img src={isThirdHover ? anim_arrow_hover : anim_arrow} alt=""/>
                    </button>
                </div>
              </div>

              <div className={isFourthHover ? "layout-div hover" : "layout-div"}>
                <div className={isFourthHover ? "first-anim hover" : "first-anim"}>
                  <div className="d-flex">
                  <div className="video-text">
                      <div className={isFourthHover ? "anim-text right hover" : "anim-text right"}>
                          <span>{t('lets_brainstorm_a_startup_idea')}</span><br/>
                          <span>{t('lets_brainstorm_a_startup_idea_2')}</span>
                      </div>
                    </div> 
                    {!isFourthReady ?
                      <Player
                        onEvent={event => {
                          if (event === 'complete') setIsFourthReady(true);
                        }}
                        keepLastFrame
                        src={animation_4s}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_4s}
                      >

                      </Player>:

                      <Player
                        onEvent={event => {
                          if (event === 'complete') {
                            setIsFourthReady(false);
                            setTimeout(() => {
                              onPlayOne()
                            },400)
                          }
                        }}
                        keepLastFrame
                        src={animation_4e}
                        style={{ height: 'auto', width: '100%' }}
                        ref={playerRef_4e}
                      >

                      </Player>
                    }
                    
                  </div>
                </div>
                <div className="partner-button fourth" onMouseEnter={() => { handleFourthMouseEnter() }}
                                                onMouseLeave={() => { handleFourthMouseLeave() }} 
                                                onClick={() => { navigate('/homepage/startups'); context.onRenderTrue();}}>
                    <span className="description-text">{t('startups')}</span>
                    <button className="anim-button"  >
                      <img src={isFourthHover ? anim_arrow_hover : anim_arrow} alt=""/>
                    </button>
                </div>
              </div>

              

              </MDBContainer>
              <div className="d-flex justify-content-center align-items-center pt-4">
                <button className="skip-button"  onClick={() => { navigate('/homepage'); 
                                      context.onRenderTrue();
                            }}>Skip <img src={skip_arrow} alt=""/>
                </button>
              </div>
            </div>
            
                         
            
            
            
            <div className="d-block d-lg-none carousel-slide" style={{maxWidth: "100%"}}>
              <Carousel showThumbs={false}   
                        onSwipeEnd={() => {
                          if(activeSlideItem === "0"){
                            playerRef_2sRespo.current.stop();
                            playerRef_3sRespo.current.stop();
                            playerRef_4sRespo.current.stop();
                          }
                          if(activeSlideItem === "1"){
                            playerRef_1sRespo.current.stop();
                            playerRef_3sRespo.current.stop();
                            playerRef_4sRespo.current.stop();
                          }
                          if(activeSlideItem === "2"){
                            playerRef_1sRespo.current.stop();
                            playerRef_2sRespo.current.stop();
                            playerRef_4sRespo.current.stop();
                          }
                          if(activeSlideItem === "3"){
                            playerRef_1sRespo.current.stop();
                            playerRef_2sRespo.current.stop();
                            playerRef_3sRespo.current.stop();
                          }
                          
                          setTimeout(() => {
                          playerRef_5s.current.setPlayerSpeed(.7)
                          playerRef_5s.current.play()
                        },500)}}
                        verticalSwipe={"natural"} 
                        showStatus={false}  
                        preventMovementUntilSwipeScrollTolerance={true} 
                        showDots={false}
                        onChange={(e) => {setActiveSlideItem(e.toString())}}
                        showIndicators={false}
                        swipeScrollTolerance={50}>
                
                
                <div className="first-anim-respo">
                  
                    <Player
                      keepLastFrame
                      src={animation_1s_o}
                      style={{ height: 'auto', width: '100%' }}
                      ref={playerRef_1sRespo}
                    />
                          
                    <div className="anim-text">
                        <span>{t('be_a_helping_paw_for')}</span><br/>
                        <span>{t('be_a_helping_paw_for_2')}</span>
                        <div className="slide-anim d-flex justify-content-center align-items-center">
                        <Player
                          onEvent={event => {
                            if (event === 'complete')  onSwipePlay_1()
                          }}
                          
                          autoplay
                          src={animation_swipe}
                          style={{ height: 'auto', width: '50%' }}
                          ref={playerRefSwipe_1}
                        />
                        </div>
                    </div>
                    
                  
                </div>
                <div className="first-anim-respo">
                  
                    <Player
                      keepLastFrame
                      src={animation_2s_o}
                      style={{ height: 'auto', width: '100%' }}
                      ref={playerRef_2sRespo}
                    />
                          
                    <div className="anim-text">
                          <span>{t('develop_an_app_based')}</span><br/>
                          <span>{t('develop_an_app_based_2')}</span>
                        <div className="slide-anim">
                        <Player
                          onEvent={event => {
                            if (event === 'complete')   onSwipePlay_2()
                          }}
                          
                          autoplay
                          src={animation_swipe}
                          style={{ height: 'auto', width: '50%' }}
                          ref={playerRefSwipe_2}
                        />
                        </div>
                    </div>
                    
                  
                </div>
                <div className="first-anim-respo">
                  
                    <Player
                      keepLastFrame
                      src={animation_3s_o}
                      style={{ height: 'auto', width: '100%' }}
                      ref={playerRef_3sRespo}
                    />
                          
                    <div className="anim-text">
                          <span>{t('gain_my_business_with')}</span><br/>
                          <span>{t('gain_my_business_with_2')}</span>
                        <div className="slide-anim">
                        <Player
                          onEvent={event => {
                            if (event === 'complete')  onSwipePlay_3()
                          }}
                          
                          autoplay
                          src={animation_swipe}
                          style={{ height: 'auto', width: '50%' }}
                          ref={playerRefSwipe_3}
                        />
                        </div>
                    </div>
                    
                  
                </div>
                <div className="first-anim-respo">
                  
                    <Player
                      keepLastFrame
                      src={animation_4s_o}
                      style={{ height: 'auto', width: '100%' }}
                      ref={playerRef_4sRespo}
                    />
                          
                    <div className="anim-text">
                        <span>{t('lets_brainstorm_a_startup_idea')}</span><br/>
                        <span>{t('lets_brainstorm_a_startup_idea_2')}</span>
                        <div className="slide-anim">
                        <Player
                          onEvent={event => {
                            if (event === 'complete')   onSwipePlay_4()
                          }}
                          
                          autoplay
                          src={animation_swipe}
                          style={{ height: 'auto', width: '50%' }}
                          ref={playerRefSwipe_4}
                        />
                        </div>
                    </div>
                    
                  
                </div>
            
              </Carousel>
              <div className="d-flex justify-content-center pt-1 px-2 pb-4">
              <button className="skip-button"  onClick={() => { navigate('/homepage'); 
                                      context.onRenderTrue();
                            }}>Skip 
              </button>
              <button className="anim-button d-flex justify-content-center align-items-center"  onClick={() => { 
                                    if(activeSlideItem === "0"){
                                      navigate('/homepage/tech');
                                      context.onSetActiveItemRespo("1");
                                    }
                                    if(activeSlideItem === "1"){
                                      navigate('/homepage/agencies');
                                      context.onSetActiveItemRespo("1");
                                    }
                                    if(activeSlideItem === "2"){
                                      navigate('/homepage/private');
                                      context.onSetActiveItemRespo("1");
                                    }
                                    if(activeSlideItem === "3"){
                                      navigate('/homepage/startups');
                                      context.onSetActiveItemRespo("1");
                                    }
                                    context.onRenderTrue();
                          }}><span className="description-text">{activeSlideItem === "0" ? t('im_from_agency'): 
                                                                 activeSlideItem === "1" ? t('im_from_techcompanies'):
                                                                 activeSlideItem === "2" ? t('im_from_private'):
                                                                 activeSlideItem === "3" ? t('im_from_startups'):""
                                                                }</span><img src={skip_arrow} alt=""/></button>
              </div>
            </div>
          </div>
      </div>
    </motion.div>
  );
};

export default withTranslation()(MainPage);
