// react imports
import React, { useContext, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import AppContext from '../../AppContext';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import design_element_6 from "../../assets/media/images/interb2bike.png";
import design_element_7 from "../../assets/media/images/borse.png";
import design_element_8 from "../../assets/media/images/jandc.png";
import design_element_11 from "../../assets/media/images/naviplan.png";
import design_element_12 from "../../assets/media/images/danfos.png";
import design_element_13 from "../../assets/media/images/alakreform.png";
import design_element_14 from "../../assets/media/images/myensign.png";
import design_element_15 from "../../assets/media/images/Group 25.png";
import design_element_16 from "../../assets/media/images/Group 24.png";
import design_element_17 from "../../assets/media/images/references.png";

import cursor from "../../assets/media/images/cursor.svg";
import pay from "../../assets/media/images/pay.svg";
import swap from "../../assets/media/images/swap.svg";
import database from "../../assets/media/images/database.svg";

import email from "../../assets/media/images/email.svg";
import ui from "../../assets/media/images/ui.svg";
import filters from "../../assets/media/images/filters.svg";
import discount from "../../assets/media/images/discount.svg";

import toolkit from "../../assets/media/images/toolkit.svg";
import invoice from "../../assets/media/images/invoice.svg";
import business from "../../assets/media/images/business.svg";
import tax from "../../assets/media/images/tax.svg";

import shop from "../../assets/media/images/shop.svg";
import valuta from "../../assets/media/images/valuta-1.svg";
import catalog from "../../assets/media/images/catalog.svg";
import designer from "../../assets/media/images/3d.svg";

import { MDBRow, MDBCol, MDBCard, MDBCarousel,
  MDBCarouselItem, MDBContainer, MDBCollapse
   } from "mdb-react-ui-kit";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



// CUSTOM COMPONENTS



const ReferencesSectionRespo = (props) => {


  // REACT ROUTER PARAMS - for processing url
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  
  const [showFirstElement_1, setShowFirstElement_1] = useState(true);
  const [showSecondElement_1, setShowSecondElement_1] = useState(false);

  const [showFirstElement_2, setShowFirstElement_2] = useState(true);
  const [showSecondElement_2, setShowSecondElement_2] = useState(false);

  const [showFirstElement_3, setShowFirstElement_3] = useState(true);
  const [showSecondElement_3, setShowSecondElement_3] = useState(false);

  const [showFirstElement_4, setShowFirstElement_4] = useState(true);
  const [showSecondElement_4, setShowSecondElement_4] = useState(false);

  const [showFirstElement_5, setShowFirstElement_5] = useState(true);
  const [showSecondElement_5, setShowSecondElement_5] = useState(false);

  const [showFirstElement_6, setShowFirstElement_6] = useState(true);
  const [showSecondElement_6, setShowSecondElement_6] = useState(false);

  const [showFirstElement_7, setShowFirstElement_7] = useState(true);
  const [showSecondElement_7, setShowSecondElement_7] = useState(false);
  
  // MULTILANGUAGE
  const { t } = i18n;

  const responsive = {
    ipad: {
        breakpoint: { max: 1000, min: 800 },
        items: 2,
        partialVisibilityGutter: 1
    },
    small: {
        breakpoint: { max: 800, min: 0 },
        items: 1,
        partialVisibilityGutter: 1
    }
  };

  const toggleFirstElement_1 = () => {
    setShowFirstElement_1(!showFirstElement_1)
    setShowSecondElement_1(!showSecondElement_1)
  }
  const toggleFirstElement_2 = () => {
    setShowFirstElement_2(!showFirstElement_2)
    setShowSecondElement_2(!showSecondElement_2)
  }
  const toggleFirstElement_3 = () => {
    setShowFirstElement_3(!showFirstElement_3)
    setShowSecondElement_3(!showSecondElement_3)
  }
  const toggleFirstElement_4 = () => {
    setShowFirstElement_4(!showFirstElement_4)
    setShowSecondElement_4(!showSecondElement_4)
  }
  const toggleFirstElement_5 = () => {
    setShowFirstElement_5(!showFirstElement_5)
    setShowSecondElement_5(!showSecondElement_5)
  }
  const toggleFirstElement_6 = () => {
    setShowFirstElement_6(!showFirstElement_6)
    setShowSecondElement_6(!showSecondElement_6)
  }
  const toggleFirstElement_7 = () => {
    setShowFirstElement_7(!showFirstElement_7)
    setShowSecondElement_7(!showSecondElement_7)
  }

  
  

 

 
  return (
    <section className="fourth-section"  id="references-respo">
      <div className="references-div pb-5" style={{width: "100%"}}>
        <img src={design_element_17} className="design-element-17"/>
        <div className="d-flex justify-content-start align-items-center" style={{width: "100%"}}>
          <img src={design_element_15} className="design-element-15"/>
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{width: "100%"}}>
          <span>{t('references')}</span>
        </div>
        <div className="d-flex justify-content-end align-items-center" style={{width: "100%"}}>
          <img src={design_element_16} className="design-element-16"/>
        </div>
      </div>
      <MDBContainer className="carousel-slide-2">
        <Carousel
            additionalTransfrom={0} 
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={true}
            slidesToSlide={1}
            centerMode={false}
            autoPlay={false}
            autoPlaySpeed={10050}
            keyBoardControl={false}
            customTransition="transform 1000ms ease-in-out"
            transitionDuration={1000}
            containerClass="carousel-container-slide"
            removeArrowOnDeviceType={["small", "ipad"]}
            afterChange={(previousSlide, { currentSlide, onMove }) => {
              setShowFirstElement_1(true)
              setShowSecondElement_1(false)
              setShowFirstElement_2(true)
              setShowSecondElement_2(false)
              setShowFirstElement_3(true)
              setShowSecondElement_3(false)
              setShowFirstElement_4(true)
              setShowSecondElement_4(false)
              setShowFirstElement_5(true)
              setShowSecondElement_5(false)
              setShowFirstElement_6(true)
              setShowSecondElement_6(false)
              setShowFirstElement_7(true)
              setShowSecondElement_7(false)
            }}
        >
            
            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://interb2bike.com" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_1} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>InterB2Bike</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_6}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_1} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('user_friendly_design')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={cursor}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_design_and_layout')}</span>
                      </div>
                  </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={swap}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('real_time_data')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_data_you_see')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('integrated_payment')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={pay}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('we_integrated_stripe')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={database}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('datbase_systems')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('we_are_connected_to')}</span>
                      </div>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_1()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>

            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://myensign.eu/test" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_2} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Myensign</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_14}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_2} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('jewelery_designer')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={designer}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_built_in_3D_jewellery')}</span>
                      </div>
                  </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={catalog}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('jewelery_catalog')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_predefined_jewellery_can_be')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('online_shop')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={shop}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('in_the_online_shop')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={discount}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('valuta')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_proprietary_currency_converter')}</span>
                      </div>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_2()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>

            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://e-bikeboerse.com" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_6} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>e-Bike börse</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_7}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_6} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('smart_filters')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={filters}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('to_make_the_search_for_bikes')}</span>
                      </div>
                  </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={ui}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('ux_ui_design')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_webpage_has_a')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('email_automatatization_1')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={email}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('emails_are_sent_for')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={database}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('datbase_systems')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('we_are_connected_to')}</span>
                      </div>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_6()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>

            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://jandc-world.ch" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_7} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>J&C World</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_8}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_7} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('all_in_one_toolikit')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={toolkit}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('jandc_provides_an_interface')}</span>
                      </div>
                  </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={invoice}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('invoicing')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('the_invoicing_system_is_fully')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-right">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('business_insights')}</span>
                        </div>
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={business}/>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('sellers_are_able_to_acces')}</span>
                      </div>
                    </div>

                    <div className="reference-dscripton-left">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="first-element d-flex justify-content-end align-items-center">
                          <img src={tax}/>
                        </div>
                        <div className="title">
                          <span className="d-flex align-items-center justify-content-end">{t('tax_compliance')}</span>
                        </div>
                      </div>
                      <div className="description">
                        <span className="d-flex align-items-center">{t('if_you_are_a_business_you')}</span>
                      </div>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_7()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>

            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://naviplan.ch" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_3} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Naviplan</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_11}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_3} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="description">
                      <span className="d-flex align-items-center">{t('naviplan_is_a_website')}</span>
                    </div>
                    <div className="description">
                      <span className="d-flex align-items-center">{t('naviplan_is_a_website_2')}</span>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_3()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>

            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://danfosscrimpersupport.com" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_4} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Danfoss</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_12}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_4} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="description">
                      <span className="d-flex align-items-center">{t('this_website_includes_multiple')}</span>
                    </div>
                    <div className="description">
                      <span className="d-flex align-items-center">{t('this_website_includes_multiple_2')}</span>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_4()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>

            <div className="top-section">
                <div className="link-text d-flex justify-content-center align-items-center">
                    <a href="https://videotar.rubintreka.hu" target="_blank">Open Website</a>
                </div>
                <div className="collapse-div">
                <MDBCollapse show={showFirstElement_5} className='mt-3' >
                  <div className="title-text d-flex justify-content-center align-items-center">
                      <span>Alakreform</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <img src={design_element_13}/>
                  </div>
                </MDBCollapse>
                <MDBCollapse show={showSecondElement_5} className='mt-3'>
                  <MDBContainer style={{maxWidth: "450px"}}>
                  <div className="interb2bike-details">
                    <div className="description">
                      <span className="d-flex align-items-center">{t('alakreform_was_created_for')}</span>
                    </div>
                    <div className="description">
                      <span className="d-flex align-items-center">{t('alakreform_was_created_for_2')}</span>
                    </div>
                  </div>
                  </MDBContainer>
                </MDBCollapse>
                </div>
                <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
                  <button className="tech-details-button" onClick={() => toggleFirstElement_5()}>
                    <span>Show Tech Details</span>
                  </button>
                </div>
            </div>
        </Carousel>
      </MDBContainer>
    </section>
  );
};

export default withTranslation()(ReferencesSectionRespo);
