// react imports
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import AppContext from '../../AppContext';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_5 from "../../assets/media/images/Background Design Elements.png";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";

import phone_fill from "../../assets/media/images/Phone-Fill.png";
import cloud_fill from "../../assets/media/images/Cloud-Fill.png";
import glasses_fill from "../../assets/media/images/Glasses-Fill.png";
import uxui_fill from "../../assets/media/images/UxUi-Fill.png";

import { MDBRow, MDBCol, MDBCard, MDBContainer
   } from "mdb-react-ui-kit";

import Cookies from 'universal-cookie';
const cookies = new Cookies();



// CUSTOM COMPONENTS



const TechnologieSectionRespo = (props) => {

  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);

  // STATES
  const [pathName, setPathName] = useState(window.location.pathname.split("/")[window.location.pathname.split("/").length-1])
  // MULTILANGUAGE
  const { t } = i18n;

  useEffect(() => {
    setPathName(window.location.pathname.split("/")[window.location.pathname.split("/").length-1]);
  }, [context.getState()?.isRender]);

  return (
    <section className="service-section" id="technologie-part-respo">
      <MDBContainer style={{maxWidth: "1670px"}} className="third-container">
      <img src={design_element_5} className="deisgn-element-5"/>
      <MDBRow className="m-0 py-4">
        <MDBCol md="12" center className="">
          <div className="top-text pb-2">
            <span>
            {t('our_precise_services')}
            </span>
          </div>
          <div className="middle-text pb-2">
            <span>
            {t('with_various')}
            </span>
          </div>
        </MDBCol>
        <MDBCol md="12" center className="stack-col-right text-center px-0 pt-4">
            <img src={design_element_3} className="design-element-3"/>
            <img src={design_element_4} className="design-element-4"/>
            
            <div className="technologie-1 d-flex align-items-center justify-content-center">
              <div className="mobile-apps">
                <div className="title-part ">
                    <img src={phone_fill}/>
                    <span>Mobile Apps</span>
                </div>
                <div className="description-part">
                    <span>{t('mobile_apps')}</span>
                </div>
              </div>
            </div>
            
            <div className="technologie-1 d-flex align-items-center justify-content-center">
              <div className="mobile-apps">
                <div className="title-part ">
                    <img src={cloud_fill}/>
                    <span>Web Apps</span>
                </div>
                <div className="description-part">
                    <span>{t('web_apps')}</span>
                </div>
              </div>
            </div>
            
            <div className="technologie-1 d-flex align-items-center justify-content-center">
              <div className="mobile-apps">
                <div className="title-part ">
                    <img src={glasses_fill}/>
                    <span>VR / AR Solutions</span>
                </div>
                <div className="description-part">
                    <span>{t('vr_ar_solutions')}</span>
                </div>
              </div>
            </div>

            <div className="technologie-1 d-flex align-items-center justify-content-center">
              <div className="mobile-apps">
                <div className="title-part ">
                    <img src={uxui_fill}/>
                    <span>UX / UI Design</span>
                </div>
                <div className="description-part">
                    <span>{t('ux_ui_design_text')}</span>
                </div>
              </div>
            </div>
        </MDBCol>
      </MDBRow>
      </MDBContainer>
    </section>
  );
};

export default withTranslation()(TechnologieSectionRespo);
