// react imports
import React from "react";
import { withTranslation } from 'react-i18next';

import AboutUsSectionRespo from "../../sections/AboutUsSectionRespo";
import ReferencesSectionRespo from "../../sections/ReferencesSectionRespo";
import FooterSectionRespo from "../../sections/FooterSectionRespo";
import TechStackSectionRespo from "../../sections/TechStackSectionRespo";
import YourVisionSectionRespo from "../../sections/YourVisionSectionRespo";
import FirstSectionRespo from "../../sections/FirstSectionRespo";
import TechnologieSectionRespo from "../../sections/TechnologieSectionRespo";


const HomePageTechCompaniesResponsiv = (props) => {
  
  return (
    <>
      <FirstSectionRespo/>
      <YourVisionSectionRespo/>
      <TechStackSectionRespo/>
      <TechnologieSectionRespo/>
      <AboutUsSectionRespo/>
      <ReferencesSectionRespo/>
      <FooterSectionRespo/>
    </>
  );
};

export default withTranslation()(HomePageTechCompaniesResponsiv);
