// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import EmailForm from "../common/EmailForm"

import footer_background_2 from "../../assets/media/images/Responsiv/Footer Background.png";

import { MDBRow, MDBCol
   } from "mdb-react-ui-kit";

const FooterSectionRespo = (props) => {

  
  
  // MULTILANGUAGE
  const { t } = i18n;

 
  
  return (
    <>
        <section className="eighth-section" id="contact-us-respo">
        <img src={footer_background_2} className="footer-background" id="backgroundImage-2-respo"/>
        
            <MDBRow className="m-0 px-3 d-flex justify-content-end align-items-center">
            <MDBCol md="12" center className="middle-col-left px-0">
            <MDBRow className="m-0 py-2" style={{maxWidth: "600px"}}>
                <span className="description-text">{t('send_us_a_message')}</span>
                </MDBRow>
                <MDBRow className="m-0 py-2" style={{maxWidth: "600px"}}>
                <span className="title-text">{t('your_inquiries_matter_and')}</span>
                </MDBRow>
                <MDBRow className="m-0 pt-4" style={{maxWidth: "600px"}}>
                <EmailForm/>
                </MDBRow>
                <MDBRow className="m-0 py-2" style={{maxWidth: "600px"}}>
                <span className="px-3 logotext-footer"> &copy; 2023 InterB2B GmbH</span>
                </MDBRow>
            </MDBCol>
            
            
            </MDBRow>
        
        </section>
    </>


  );
};

export default withTranslation()(FooterSectionRespo);
