import React, { Component } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthToken = () => {
  return cookies.get('authtoken')?.token;
}


class AppContextProvider extends Component {
  state = {
    interAxios: new InterAxios('App', getAuthToken),
    
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
      this.toEnglish();
  }

  toGerman = () => {
    i18next.changeLanguage('de', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      cookies.set('mylanguage', {
          language: 'de'
        }, { path: '/'}
      );
    });
    
    //moment.locale('de');
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      cookies.set('mylanguage', {
          language: 'en'
        }, { path: '/'}
      );
    });
    
    //moment.locale('en');
  }

 
  
  
  
  setState(state) {
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }


  getState = () => {
    let stateStr = window.localStorage.getItem('state');
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  clearState = () => {

  }

  onSignIn = (responseData) => {
    cookies.set('authtoken', {
        token: responseData.authToken
      }, { path: '/'}
    );
    
    this.setState({loggedIn: true, isGyik: false});
  }

  /*onSignIn = () => {
    this.setState({loggedIn: true, isGyik: false});
  }*/

  onLogout = () => {
   cookies.remove('authtoken', { path: '/'});
    window.localStorage.removeItem('state');
    this.setState({loggedIn: false, isGyik: true});
  }

  /*onLogout = () => {
     this.setState({loggedIn: false, isGyik: true});
   }*/

  isLoggedIn = () => {
      let cookieToken = getAuthToken();
      return cookieToken !== undefined && this.parseJwt(cookieToken)?.exp > (Date.now() / 1000);
      //return this.state.loggedIn ? true : false;
  }

  parseJwt = (token) => {
      try {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          return JSON.parse(jsonPayload);
      } catch (e) {
          console.log(e);
        return null;
      }
  };

  openLoginModal = (modalName) => {
    this.setState({
      loginModalOpened: true,
      modalName: modalName
    });
  }

  isLoginModalOpened = () => {
    return this.getState()?.loginModalOpened;
  }
  closeLoginModal = () => {
    this.setState({
      loginModalOpened: false,
    });
    document.body.style.overflowY = 'scroll'
  }

  onHideScroll = (value) => {
    this.setState({isScroll: value})
  }

  onRender = () => {
    this.setState({isRender: false})
  }

  onRenderTrue = () => {
    this.setState({isRender: true})
  }

  onSetActiveItem = (tab) => {
    this.setState({activeItem: tab})
  }
  
  onSetActiveItemRespo = (tab) => {
    this.setState({activeItemRespo: tab})
  }

  onSetIsScroll = (value) => {
    this.setState({isScroll: value})
  }

  onSetNavChange = (value) => {
    this.setState({isNavChange: value})
  }

  onSetPathName = (pathname) => {
    this.setState({pathname: pathname})
  }


  render() {
      return (
          <AppContext.Provider
              value={{
                  interAxios: this.state.interAxios,
                  isLoggedIn: this.isLoggedIn,
                  onSignIn: this.onSignIn,
                  onLogout: this.onLogout,
                  getState: this.getState,
                  clearState: this.clearState,
                  isLoginModalOpened: this.isLoginModalOpened,
                  openLoginModal: this.openLoginModal,
                  closeLoginModal: this.closeLoginModal,
                  onHideScroll: this.onHideScroll,
                  toGerman: this.toGerman,
                  toEnglish: this.toEnglish,
                  onRender: this.onRender,
                  onRenderTrue: this.onRenderTrue,
                  onSetActiveItem: this.onSetActiveItem,
                  onSetActiveItemRespo: this.onSetActiveItemRespo,
                  onSetIsScroll: this.onSetIsScroll,
                  onSetPathName: this.onSetPathName,
                  onSetNavChange: this.onSetNavChange
              }}
          >
              {this.props.children}
          </AppContext.Provider>
      );
  }
}

export default AppContextProvider;
