// react imports
import React from "react";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import design_element_2 from "../../assets/media/images/Background Design Elements.svg";
import design_element_3 from "../../assets/media/images/Background Design element - Circle.svg";
import design_element_4 from "../../assets/media/images/Background Design element - Circle-1.svg";
import design_element_9 from "../../assets/media/images/landingPagePreview.svg";
import design_element_10 from "../../assets/media/images/Mobile Image.svg";


import { MDBRow, MDBCol, MDBContainer
   } from "mdb-react-ui-kit";


const YourVisionSectionSpecielRespo = (props) => {
  
  // MULTILANGUAGE
  const { t } = i18n;

 
  const pathname = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (
    <section className={pathname === 'homepage' ? "second-section-spec" : "second-section"} id="your-vision-respo">
       
        <div className="tech-title-text">
          <span>{t('try_our_responsible_team')}</span>
        </div>
        <MDBRow className="m-0 px-2 pb-4">
          {pathname === "agencies" && (
          <MDBCol md="12" center className="">
            <div className="top-text pb-2">
              <span>
              {t('the_way_we_work')}
              </span>
              
            </div>
            <div className="bottom-text">
              <span>
              1. {t('ask_for_the_free_first_consultation')}
              </span><br/>
              <span>
              2. {t('present_the_project')}
              </span><br/>
              <span>
              3. {t('we_assign_a_professional')}
              </span><br/>
              <span>
              4. {t('this_new_specification')}
              </span><br/>
              <span>
              5. {t('based_on_the_specification')}
              </span><br/>
              <span>
              6. {t('if_we_agree')}
              </span>
            </div>
          </MDBCol>)}
          {pathname === "startups" && (
          <MDBCol md="12" center className="">
            <div className="bottom-text">
              <span>
              {t('and_what_you_now_need')}
              </span><br/><br/>
              <span>
              {t('we_know_that_developing')}
              </span>
            </div>
          </MDBCol>)}
          <MDBCol md="12" className="design-col px-2">
              <div className="first-card">
                  <img src={design_element_3} className="design-element-3"/>
                  <img src={design_element_9} className="design-element-9"/>
                  <img src={design_element_10} className="design-element-10"/>
              </div>
          </MDBCol>
        </MDBRow>
      {pathname === "agencies" && (<>
      <div className="bottom-text px-3">
          <span>{t('of_course_sometimes_changes_occur')}</span>
      </div>
      <div className="tech-footer-contact-text">
            <span>{t('ask_for_free_consultation_now')}</span>
      </div>
      </>)}
      {pathname === "startups" && (<>
      <div className="bottom-text px-3">
          <span>{t('bring_your_idea')}</span>
      </div>
      <div className="tech-footer-contact-text">
            <span>{t('be_the_next_unicorn')}</span>
      </div>
      </>)}
      <div className="tech-footer-button pb-5">
      <button onClick={() => {
                  var element = document.getElementById('contact-us-respo');
                  var headerOffset = 59;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                
                  window.scrollTo({
                      top: offsetPosition,
                      behavior: "smooth"
                  })
                }}><span>{t('contact_us')}</span></button>
      </div>
    </section>
  );
};

export default withTranslation()(YourVisionSectionSpecielRespo);
