import React, { useContext, Component } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import i18n from '../../../i18n';
import logo from '../../../assets/media/images/logo.svg'
import AppContext from '../../../AppContext';


import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNavbar,
	MDBNavbarItem,
	MDBNavbarLink,
	MDBContainer,
	MDBNavbarNav
} from 'mdb-react-ui-kit';

class FooterPage extends Component {

  constructor(props) {
    super(props);
  };

  render() {
    return (<MDBContainer style={{maxWidth: "1670px"}}>
			<MDBRow center className="py-3 px-5">
				<MDBCol xxl="2" xl="2" lg="2" md="2" className="d-flex justify-content-start align-items-center">
				<img alt="" className="copyright-logo" src={logo} height='50'></img>
				</MDBCol>
				<MDBCol xxl="8" xl="6" lg="6" md="6" className="d-flex justify-content-center align-items-center">
					<div className="fixed-footer-navbar d-flex justify-content-center">
						<MDBNavbar expand='lg' light  >
							<MDBContainer style={{maxWidth: "1475px"}}>
								<MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
									<div className="d-flex justify-content-center align-items-center">
										<MDBNavbarItem>
											<MDBNavbarLink href="/impressum">{this.props.t('impressum')}</MDBNavbarLink>
										</MDBNavbarItem>
										<MDBNavbarItem>
											<MDBNavbarLink href="/useragreement">{this.props.t('termsOfUse')}</MDBNavbarLink>
										</MDBNavbarItem>
										<MDBNavbarItem>
											<MDBNavbarLink href="/cookiepolicy">{this.props.t('cookiePolicy')}</MDBNavbarLink>
										</MDBNavbarItem>
									</div>
								</MDBNavbarNav>
							</MDBContainer>
						</MDBNavbar>
					</div>
				</MDBCol>
				<MDBCol xxl="2" xl="4" lg="4" md="4" className="d-flex justify-content-end align-items-center">
						<span className="px-3 logotext-footer"> &copy; 2023 InterB2B GmbH</span>
				</MDBCol>
			</MDBRow>
			</MDBContainer>
    );
  }
}

function FooterPageFunc(props) {
	let [searchParams] = useSearchParams();
	const { t } = i18n;
	const navigate = useNavigate();
	const context = useContext(AppContext);
	return <FooterPage searchParams={searchParams} navigate={navigate}  context={context} t={t}/>;
  }
  
  export default withTranslation()(FooterPageFunc);
