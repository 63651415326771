// react imports
import React, { useContext, useState, useEffect } from "react";
import AppContext from '../../../AppContext';
import Cookies from 'universal-cookie';
import Flags from 'country-flag-icons/react/3x2'

// MDBOOTSTRAP IMPORTS
import {
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavbarLink
} from 'mdb-react-ui-kit';
const cookies = new Cookies();

const HeaderLanguageSelector = (props) => {
  // STATES
  const [language, setLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'EN' : 'DE');
  // REFS
  const context = useContext(AppContext);
  // EVENTS
  const getLanguage = (language) => {
    setLanguage(language)
    if(language === 'EN'){
      context.toEnglish();
      context.onRenderTrue();
    }else{
      context.toGerman();
      context.onRenderTrue();
    }
  };

  useEffect(() => {
    if(cookies.get('mylanguage')?.language === 'en'){
      setLanguage('EN')
    }else{
      setLanguage('DE')
    }
    context.onRender();
  }, [context.getState()?.isRender]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <MDBNavbarItem link childTag='button' onClick={() => getLanguage('EN')} className="me-3">
        <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'en'} href='#'><Flags.GB style={{borderRadius: "2px", width: "40px"}}/></MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem link childTag='button' onClick={() => getLanguage('DE')}>
        <MDBNavbarLink active={cookies.get('mylanguage')?.language === 'de'} href='#'><Flags.DE style={{borderRadius: "2px", width: "40px"}}/></MDBNavbarLink>
      </MDBNavbarItem>
    </div>
   
  );
};

export default HeaderLanguageSelector;
